import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

const Slideshow = () => {
  const [slideshows, setSlideshows] = useState([]);

  useEffect(() => {
    loadSlideshows();
    //eslint-disable-next-line
  }, []);

  async function loadSlideshows() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/slideshows?active=yes&sort=ordering`
      );

      setSlideshows(res.data.data);
    } catch (err) {
      console.log(err);
    }
  }

  SwiperCore.use([Navigation]);

  return (
    <section id="homeBanners">
      <div className="container">
        <div id="homeSlideshow">
          {slideshows.length > 0 && (
            <Swiper slidesPerView={1} navigation>
              {slideshows.map((slide) => (
                <SwiperSlide key={slide._id}>
                  <Link to={slide.urlLink}>
                    <img
                      src={`${process.env.REACT_APP_APIURL}uploads/${slide.imageFileName}`}
                      alt={slide.title}
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </section>
  );
};

export default Slideshow;
