import React, { useState, useContext, useEffect, Fragment } from "react";
import AlertContext from "../../context/alert/alertContext";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

const UpgradeToBusinessPartner = () => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;

  const [sellerDetail, setSellerDetail] = useState({
    address: "",
    postcode: "",
    provinceId: "",
    districtId: "",
    subdistrictId: "",
    upgradeToSellerStatus: "",
    registerDone: false,
    role: "",
    businessPartnerEndDate: "",
    referralCode: "",
    countOrder: "",
    bankName: "",
    accountNo: "",
    accountName: "",
    birthday: "",
    sex: "",
    alumni: "",
    job: "",
    socialmedia: "",
    socialmedialink: "",
    npwp: "",
    isInstructor: false,
  });

  const {
    address,
    provinceId,
    districtId,
    subdistrictId,
    upgradeToSellerStatus,
    postcode,
    registerDone,
    role,
    referralCode,
    businessPartnerEndDate,
    countOrder,
    bankName,
    accountNo,
    accountName,
    birthday,
    sex,
    alumni,
    job,
    socialmedia,
    socialmedialink,
    npwp,
    isInstructor,
  } = sellerDetail;

  //const [image, setImage] = useState({});
  // const onChangeImage = (e) => {
  //   if (e.target.files[0].size > 5242880) {
  //     setAlert(`Max image size 5MB`, "danger");
  //   } else if (!e.target.files[0].type.startsWith("image")) {
  //     setAlert(`File must in format of image`, "danger");
  //   } else {
  //     setImage(e.target.files[0]);
  //   }
  // };

  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [subdistricts, setSubdistricts] = useState([]);

  useEffect(() => {
    loadProvinces();
    loadUser();
    //eslint-disable-next-line
  }, []);

  async function loadUser() {
    try {
      const userdata = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/users/getuserdetail`
      );

      const countOrder = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/orders/countorder`
      );

      setSellerDetail({
        ...sellerDetail,
        role: userdata.data.data.role,
        upgradeToSellerStatus: userdata.data.data.upgradeStatus,
        businessPartnerEndDate: userdata.data.data.businessPartnerEndDate,
        referralCode: userdata.data.data.referralCode,
        countOrder: countOrder.data.data,
        isInstructor: userdata.data.data.isInstructor,
      });
    } catch (err) {
      console.log(err.message, "danger");
    }
  }

  async function loadProvinces() {
    try {
      //load provinces
      const provinces = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/shipping/provinces`
      );

      setProvinces(provinces.data.data);
    } catch (err) {
      console.log(err.message, "danger");
    }
  }

  const onChange = (e) => {
    setSellerDetail({ ...sellerDetail, [e.target.name]: e.target.value });
  };

  const onChangeProvince = async (e) => {
    const provinceId = e.target.value;
    setSellerDetail({ ...sellerDetail, provinceId });
    //reset subdistricts
    setSubdistricts([]);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/shipping/districts/${provinceId}`
      );
      setDistricts(res.data.data);
    } catch (err) {
      setAlert(err.message, "danger");
    }
  };

  const onChangeDistrict = async (e) => {
    const districtId = e.target.value;
    setSellerDetail({ ...sellerDetail, districtId: districtId });

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/shipping/subdistricts/${districtId}`
      );

      setSubdistricts(res.data.data);
    } catch (err) {
      setAlert(err.message, "danger");
    }
  };

  const onChangeSubdistrict = async (e) => {
    const subdistrictId = e.target.value;
    setSellerDetail({
      ...sellerDetail,
      subdistrictId: subdistrictId,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (
      address === "" ||
      provinceId === "" ||
      districtId === "" ||
      subdistrictId === "" ||
      bankName === "" ||
      accountName === "" ||
      accountNo === "" ||
      birthday === "" ||
      sex === "" ||
      alumni === "" ||
      job === ""
      // image === ""
    ) {
      setAlert("Mohon melengkapi required field", "danger");
    } else {
      confirmAlert({
        title: "Sudah Yakin ?",
        message: `Sudah yakin mau ajukan upgrade ?`,
        buttons: [
          {
            label: "Ya",
            onClick: () => submitUpgrade(),
          },
          {
            label: "Tidak",
            onClick: () => console.log("Cancel save..."),
          },
        ],
      });
    }

    async function submitUpgrade() {
      let formData = new FormData();
      formData.append("address", address);
      if(postcode) {
        formData.append("postcode", postcode);
      }
      formData.append("provinceId", provinceId);
      formData.append("districtId", districtId);
      formData.append("subdistrictId", subdistrictId);
      formData.append("bankName", bankName);
      formData.append("accountNo", accountNo);
      formData.append("accountName", accountName);
      formData.append("birthday", birthday);
      formData.append("sex", sex);
      formData.append("alumni", alumni);
      formData.append("job", job);
      if(socialmedia) {
        formData.append("socialmedia", socialmedia);
        formData.append("socialmedialink", socialmedialink);
      }
      if(npwp) {
        formData.append("npwp", npwp);
      }
      // formData.append("files", image);

      try {
        const res = await axios.put(
          `${process.env.REACT_APP_APIURL}api/v1/users/upgradeToBusinesspartner`,
          formData
        );

        setSellerDetail({
          ...sellerDetail,
          upgradeToSellerStatus: res.data.data.upgradeStatus,
        });

        setAlert(
          "Data berhasil dikirim. Silahkan menunggu verifikasi",
          "success"
        );
      } catch (err) {
        setAlert(err.response.data.error, "danger");
      }
    }
  };

  if (isInstructor === false && countOrder === 0) {
    return (
      <>
        <h3>Daftar menjadi Business Partner</h3>
        <p>
          Dapatkan kesempatan menjadi Business Partner, dengan telah mengikuti
          minimal 1 program Akademi NLP Indonesia. Dapatkan benefit redeem dari
          program Member Get Member.
        </p>
      </>
    );
  }

  return (
    <Fragment>
      <h3>Daftar menjadi Business Partner</h3>
      <p style={{fontSize:'12px'}}>* Wajib diisi</p>
      <hr />
      {(() => {
        if (upgradeToSellerStatus === "in process") {
          return (
            <p
              style={{
                padding: "10px 20px 10px 20px",
                color: "white",
                backgroundColor: "green",
              }}
            >
              Pengajuan Business Partner sedang diverifikasi oleh admin.
              Silahkan menunggu. Anda akan mendapatkan hasil melalui notifikasi
              melalui email.
            </p>
          );
        } else if (upgradeToSellerStatus === "approved") {
          return (
            <p
              style={{
                padding: "10px 20px 10px 20px",
                color: "white",
                backgroundColor: "green",
              }}
            >
              Selamat! pendaftaran Anda sebagai Business Partner sudah
              disetujui.
              <br />
              Business Partner Anda berlaku sd{" "}
              {moment(businessPartnerEndDate).format("DD MMM YYYY")}.
              <br />
              Kode Referral Business Partner Anda:
              <br />
              {referralCode}
            </p>
          );
        } else if (upgradeToSellerStatus === "rejected") {
          return (
            <p
              style={{
                padding: "10px 20px 10px 20px",
                color: "white",
                backgroundColor: "brown",
              }}
            >
              Maaf. pendaftaran Anda sebagai business partner belum disetujui.
              Silahkan melengkapi dan perbaharui dokumen, dan silahkan mencoba
              mendaftar kembali.
            </p>
          );
        } else {
          return (
            <div className="details-wrap">
              <form onSubmit={onSubmit} className="form-login">
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: "0.5rem 1rem",
                  }}
                >
                  <div className="form-contact">
                    <label>Tanggal lahir *</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      required
                      name="birthday"
                      value={birthday}
                      onChange={onChange}
                      placeholder="Contoh: 1 januari 1980..."
                    />
                  </div>
                  <div className="form-contact">
                    <label>Jenis Kelamin *</label>
                    <br />
                    <select
                      className="wid-100"
                      required
                      value={sex}
                      name="sex"
                      onChange={onChange}
                    >
                      <option value="">Pilih jenis kelamin</option>
                      <option value="lelaki">Lelaki</option>
                      <option value="perempuan">Perempuan</option>
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Alumni NLP Indonesia ? *</label>
                    <br />
                    <select
                      className="wid-100"
                      required
                      name="alumni"
                      value={alumni}
                      onChange={onChange}
                    >
                      <option value="">Pilih status alumni...</option>
                      <option value="Ya">Ya</option>
                      <option value="Tidak">Tidak</option>
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Pekerjaan *</label>
                    <br />
                    <select
                      className="wid-100"
                      required
                      name="job"
                      value={job}
                      onChange={onChange}
                    >
                      <option value="">Pilih pekerjaan...</option>
                      <option value="Pengusaha">Pengusaha</option>
                      <option value="Pegawai swasta">Pegawai swasta</option>
                      <option value="Profesi">Profesi</option>
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Sosial Media</label>
                    <br />
                    <select
                      className="wid-100"
                      name="socialmedia"
                      value={socialmedia}
                      onChange={onChange}
                    >
                      <option value="">Pilih sosmed...</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Youtube">Youtube</option>
                    </select>
                  </div>
                  {socialmedia && <div className="form-contact">
                    <label>Nama Sosmed *</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      required
                      name="socialmedialink"
                      value={socialmedialink}
                      onChange={onChange}
                      placeholder="Isi nama sosmed Anda..."
                    />
                  </div>}
                  <div className="form-contact">
                    <label>Nomor NPWP</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      name="npwp"
                      value={npwp}
                      onChange={onChange}
                      placeholder="Nomor NPWP Anda..."
                    />
                  </div>
                </div>
                <div className="form-contact">
                  <label>Alamat Lengkap *</label>
                  <br />
                  <input
                    className="wid-100"
                    type="text"
                    required
                    name="address"
                    value={address}
                    onChange={onChange}
                    placeholder="Tulis alamat Anda..."
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: "0.5rem 1rem",
                  }}
                >
                  <div className="form-contact">
                    <label>Provinsi *</label>
                    <br />
                    <select
                      className="wid-100"
                      required
                      value={provinceId || ""}
                      onChange={onChangeProvince}
                    >
                      <option value="">Pilih Provinsi</option>
                      {provinces.map((province) => (
                        <option
                          value={province.rajaongkir_province_id}
                          key={province.rajaongkir_province_id}
                        >
                          {province.province}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Kota/Kabupaten *</label>
                    <br />
                    <select
                      className="wid-100"
                      value={districtId || ""}
                      required
                      onChange={onChangeDistrict}
                    >
                      <option value="">Pilih Kota/kabupaten...</option>
                      {districts.map((district) => (
                        <option
                          value={district.rajaongkir_id_district}
                          key={district.rajaongkir_id_district}
                        >
                          {district.district}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Kecamatan *</label>
                    <br />
                    <select
                      className="wid-100"
                      value={subdistrictId || ""}
                      required
                      onChange={onChangeSubdistrict}
                    >
                      <option value="">Pilih Kecamatan...</option>
                      {subdistricts.map((subdistrict) => (
                        <option
                          value={subdistrict.rajaongkir_id_subdistrict}
                          key={subdistrict.rajaongkir_id_subdistrict}
                        >
                          {subdistrict.subdistrict}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-contact">
                    <label>Kode Pos</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      value={postcode}
                      name="postcode"
                      onChange={onChange}
                      placeholder="Tulis kodepos..."
                    />
                  </div>
                  {/* <div className="form-contact">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${process.env.REACT_APP_APIURL}uploads/${identityCard}`}
                    >
                      <img
                        src={`${process.env.REACT_APP_APIURL}uploads/${identityCard}`}
                        alt=""
                        style={{ width: "150px", marginRight: "5px" }}
                      />
                    </a>
                    <label className="form_label" htmlFor="files">
                      Upload KTP
                    </label>
                    <input
                      type="file"
                      name="files"
                      id="files"
                      onChange={onChangeImage}
                      required
                    />
                    <label className="form_label" htmlFor="files">
                      Format: jpg, jpeg, png
                    </label>
                  </div> */}
                </div>
                <h3>Informasi bank untuk pengiriman Referral Bonus</h3>
                <hr />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    gridGap: "0.5rem 1rem",
                  }}
                >
                  <div className="form-contact">
                    <label>Nama Bank *</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      required
                      value={bankName}
                      name="bankName"
                      onChange={onChange}
                      placeholder="Tulis nama bank..."
                    />
                  </div>
                  <div className="form-contact">
                    <label>Nama Rekening *</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      required
                      value={accountName}
                      name="accountName"
                      onChange={onChange}
                      placeholder="Tulis nama rekening..."
                    />
                  </div>
                  <div className="form-contact">
                    <label>Nomor Rekening *</label>
                    <br />
                    <input
                      className="wid-100"
                      type="text"
                      required
                      value={accountNo}
                      name="accountNo"
                      onChange={onChange}
                      placeholder="Tulis nomor rekening..."
                    />
                  </div>
                </div>
                <br />
                <button
                  type="submit"
                  className="btn-regis"
                  style={{ width: "100%", cursor: "pointer" }}
                >
                  DAFTAR SEKARANG
                </button>
              </form>
            </div>
          );
        }
      })()}
    </Fragment>
  );
};

export default UpgradeToBusinessPartner;
