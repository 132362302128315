import React, { useContext, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import ReferralBonusItem from "./ReferralBonusItem";
import AuthContext from "../../../context/auth/authContext";

function ReferralBonus() {
  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;

  const [userData, setUserData] = useState({
    referralCode: "",
    totalReferralPoint: "",
  });
  const { referralCode, totalReferralPoint } = userData;

  const [sales, setSales] = useState([]);
  const [pagination, setPagination] = useState({
    total: null,
    per_page: null,
    current_page: 1,
    total_pages: null,
    start_index: null,
  });
  const {
    total,
    per_page,
    current_page,
    total_pages,
    start_index,
  } = pagination;

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (referralCode) {
      loadSales(1);
    }

    //eslint-disable-next-line
  }, [userData]);

  async function loadUser() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/users/getuserdetail`
      );

      setUserData({
        referralCode: res.data.data.referralCode,
        totalReferralPoint: res.data.data.totalReferralPoint,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function loadSales(pageNumber) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/orders/byReferralCode/${referralCode}/${pageNumber}`
      );

      setSales(res.data.data);

      setPagination({
        ...pagination,
        total: res.data.total,
        per_page: res.data.per_page,
        current_page: res.data.page,
        total_pages: res.data.total_pages,
        start_index: res.data.startIndex,
      });
    } catch (err) {
      console.log(err.message);
    }
  }

  const pageNumbers = [];
  let renderPageNumbers;

  if (total !== null) {
    for (let i = 1; i <= Math.ceil(total / per_page); i++) {
      pageNumbers.push(i);
    }

    renderPageNumbers = pageNumbers.map((number) => {
      let classLink = current_page === number ? "active" : "";
      let classList = current_page === number ? "active" : "";
      return (
        <li className={classList} key={number}>
          <Link to="#" className={classLink} onClick={() => loadSales(number)}>
            {number}
          </Link>
        </li>
      );
    });
  }

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  if (currentUser && currentUser.role !== "partner") {
    return <Redirect to="/account/dashboard" />;
  } else {
    return (
      <>
        <div className="information-title">
          Referral Bonus Anda (Referral Code: {referralCode})
        </div>
        <p>
          <strong>SALDO BONUS: {formatter.format(totalReferralPoint)}</strong>{" "}
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link
            to="/account/referral-bonus-claim"
            className="btn-regis"
            style={{ padding: "5px" }}
          >
            REDEEM BONUS
          </Link>
        </p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Tgl Order</th>
              <th>Nama Member</th>
              <th>Program</th>
              <th>Jumlah</th>
              <th>Bonus Anda</th>
            </tr>
          </thead>
          <tbody>
            {sales &&
            sales.map((sales, index) => (
              <ReferralBonusItem sales={sales} key={index} />
            ))}
          </tbody>
        </table>
        <div className="pagination-wrapper" style={{ borderTop: "none" }}>
          <ul className="pagination">
            <li>
              <Link to="#" onClick={() => loadSales(1)}>
                <i className="fa fa-angle-double-left"></i>
              </Link>
            </li>
            {renderPageNumbers}
            <li>
              <Link to="#" onClick={() => loadSales(total_pages)}>
                <i className="fa fa-angle-double-right"></i>
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default ReferralBonus;
