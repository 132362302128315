import React, { useEffect, useState } from "react";
import axios from "axios";

import ServiceItem from "./ServiceItem";

const Services = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    loadServices();
    //eslint-disable-next-line
  }, []);

  async function loadServices(pageNumber = 1) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/services/all/${pageNumber}`
      );

      setServices(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <section className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            LAYANAN
            <br />
            KAMI
          </h1>
          {services.length > 0 &&
            services.map((service) => (
              <ServiceItem service={service} key={service._id} />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
