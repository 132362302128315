import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const CreativeThinking = () => {

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    loadPartners();
    //eslint-disable-next-line
  }, []);

  async function loadPartners() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/all/frontend`
      );

      setPartners(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <section className="py-2" id="layananDetail">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">CREATIVE THINKING</h1>
          <div className="layanan layanan-intro">
            <img
              className="img-service"
              src={`${process.env.REACT_APP_APIURL}uploads/programs/thumbnail_creative-thinking.jpg`}
              alt=""
            />
            <div className="layanan-info"></div>
          </div>

          <div className="layanan-detail">
            <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Tujuan</p>
            <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
            <li>Mampu membuka kebuntuan berpikir dan menghasilkan ide-ide kreatif</li>
            <li>Mampu memberi kontribusi positif dalam brainstorming tim</li>
            <li>Memberi sudut pandang berbeda dalam menyelesaikan masalah produk atau jasa</li>
            </ul>  
          </div>
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gridGap:'1rem', paddingTop:'30px'}}>
            <div>
              <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Materi</p>
              <p><strong>MEMBUKA PIKIRAN</strong></p>
              <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
                <li>3 Syarat ide yang kreatif</li>
                <li>Menyadari Batasan-batasan berpikir</li>
                <li>3 Pertanyaan yang menantang diri untuk thinking outside the box</li>
                <li>Bagaimana membumikan ide liar</li>
              </ul>   
              <br />

              <p><strong>MENGHASILKAN IDE KREATIF</strong></p>
              <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
              <li>3 Cara umum mencari ide</li>
              <li>Memahami perbedaan ciri-ciri ide dan solusi</li>
              <li>Memahami perbedaan sifat ide dan konsep</li>
              <li>Teknik mencari ide dengan analogi</li>
              </ul>   
              
              <br />

              <div className="btn-service btn-program py-2" style={{position:'static'}}>
                <Link to="/kontak">INFORMASI LEBIH LANJUT</Link>
              </div>
            </div>
            <div>
              <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Format Program<br /><span style={{color:'black'}}>2x90 Menit</span></p>
              <br />
              <div style={{width:'100%', background:'#eee', padding:'10px 20px'}}>
                  <p style={{color: '#bb2024', fontSize:'1.2rem', fontWeight:'bold'}}>Trainer</p>
                  <br />
                  <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'1rem' }}>
                  {partners.length > 0 &&
              partners.map((partner, index) => {
                return (
                  <div className="teach-1">
                   <Link to={`/instruktur/${partner.slug}`}>
                      <img
                        src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                        alt=""
                      />
                    </Link>
                    <p style={{ textTransform: "uppercase", borderBottom:'none', color:'black', fontWeight:'normal', fontSize:'0.9rem' }}>
                      {partner.name}
                    </p>
                  </div>
                );
              })}


                  </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default CreativeThinking;