import React from "react";

const Footer = () => {
  return (
    <>
      <footer>
        <div id="footerLogo">
          <img src={process.env.PUBLIC_URL + "/assets/img/logo.jpg"} alt="" />
        </div>
        <div className="footer-info">
          <div className="container">
            <h2>Akademi NLP Indonesia</h2>
            <div id="footerSection">
              <div className="footer-address">
                <h3>Alamat</h3>
                <p>
                  SOHO Podomoro City
                  <br />
                  18th floor, unit 1807
                  <br />
                  Jl. S. Parman Kav 28, Tanjung Duren
                  <br />
                  Grogol Petamburan, Jakarta Barat
                  <br />
                  11470
                </p>
              </div>

              <div className="footer-contact">
                <h3>Hubungi Kami</h3>
                <a href="telp:02130003614">(021) 3000 3614</a>
                <br />
                <a href="mailto:info@akademinlpindonesia.co.id">
                  info@akademinlpindonesia.co.id
                </a>
              </div>
            </div>

            <div className="footerBottom">
              <div className="sosialMedia">
                <a href="#">
                  <i className="fa fa-instagram"></i>
                </a>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </div>
              <div className="copyright">
                {/* <span>Legal | Impressum | Data Privacy | Jakarta</span> */}
                <p>
                  Copyright &copy; 2020 Akademi NLP Indonesia. All Right Reserve
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <a
        href=" https://wa.me/+6282123131419"
        className="fa fa-whatsapp"
        id="floatWa"
        target="_blank"
      ></a>
    </>
  );
};

export default Footer;
