import React from "react";
import AccountMenu from "./AccountMenu";
import Dashboard from "./Dashboard";
import ChangePassword from "../ChangePassword";
import UpgradeToBusinessPartner from "./UpgradeToBusinessPartner";
import ReferralBonus from "./referralbonus/ReferralBonus";
import ReferralBonusClaim from "./referralbonus/ReferralBonusClaim";
import Orders from "./orders/Orders";

const Account = ({ match }) => {
  let accountPage;

  switch (match.params.accountPage) {
    case "dashboard":
      accountPage = <Dashboard />;
      break;
    case "ganti-password":
      accountPage = <ChangePassword />;
      break;
    case "upgrade-business-partner":
      accountPage = <UpgradeToBusinessPartner />;
      break;
    case "referral-bonus":
      accountPage = <ReferralBonus />;
      break;
    case "referral-bonus-claim":
      accountPage = <ReferralBonusClaim />;
      break;
    case "pembelian":
      accountPage = <Orders />;
      break;
    default:
      accountPage = <Dashboard />;
      break;
  }

  return (
    <>
      <section className="page-section">
        <div className="wrap container">
          <div className="row" id="mainDashboard">
            <AccountMenu />
            <div className="backendPage">{accountPage}</div>
          </div>
        </div>
      </section>
      <br />
      <br />
    </>
  );
};

export default Account;
