import React, { useContext, useEffect, Fragment, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import queryString from "query-string";
const Thankyou = (props) => {
  const [query, setQuery] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    const queryObject = queryString.parse(props.location.search);
    setQuery({
      ...query,
      status: queryObject.status || "",
    });
    setLoading(false);
  }, [props.location.search]);

  if (loading) return null;

  if (query.status === "cancel") {
    return (
      <Fragment>
        <div className="wrapp product_detail">
          <div className="row">
            <div className="col-md-12">
              <div class="ck_thankyou">
                <i class="fa fa-times-circle"></i>
                <h3>Maaf Transaksi gagal.</h3>
                <p>Silakan hubungi kami.</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="wrapp product_detail">
        <div className="row">
          <div className="col-md-12">
            <div
              class="ck_thankyou"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h3
                style={{
                  background: "green",
                  color: "white",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                Terima kasih untuk investasi Anda.
              </h3>
              <p>Transaksi Anda sudah dibuat.</p>
              <p style={{ fontSize: "16px" }}>
                Catatan: untuk pembayaran dengan menggunakan Kartu Kredit,
                tagihan yang akan tercetak di lembar tagihan kartu kredit
                pelanggan adalah atas nama ESPAY.
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Thankyou;
