import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";

function ReferralBonusItem({ sales }) {
  const [program, setProgram] = useState({
    name: "",
  });
  const { name } = program;

  useEffect(() => {
    loadProgram();
    //eslint-disable-next-line
  }, []);

  async function loadProgram() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/programs/${sales.scheduleId.program}`
      );

      setProgram({
        name: res.data.data.name,
      });
    } catch (error) {
      console.log(error);
    }
  }

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <tr>
      <td> {moment(sales.orderDate).format("DD MMM YYYY")}</td>
      <td>{sales.buyerId.name}</td>
      <td>{name}</td>
      <td>{formatter.format(sales.grandTotal)}</td>
      <td>{formatter.format(sales.bonusForReferral)}</td> 
    </tr>
  );
}

export default ReferralBonusItem;
