import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Truncate from 'react-truncate';
import axios from "axios";

const AkunProgramItem = ({ program }) => {

  const [programTaken, setProgramTaken] = useState('no');

  useEffect(() => {
    checkProgramTaken();
  }, []);

  async function checkProgramTaken() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/orders/checkusertakeprogram/${program._id}`
      );
      setProgramTaken(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="card card-program2" style={{backgroundColor:'#eee'}}>
      <img
        className="img-service"
        src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.thumbnail}`}
        alt=""
      />
      <div className="text-center">
        <h2 style={{ textTransform: "uppercase", height:'50px' }}>{program.name}</h2>
        <Truncate lines={5}>
          {program.opening}
        </Truncate>
        {programTaken === 'no' ? <div className="btn-service  py-2">
          <Link to={`/program/${program.slug}`}>LIHAT PROGRAM</Link>
        </div> : <div className="btn-service2  py-2">
          <Link to={`/program/${program.slug}`}>ANDA SUDAH IKUT <i className="fa fa-check"></i></Link>
        </div>}
        
      </div>
    </div>
  );
};

export default AkunProgramItem;