export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_SOSMED_SUCCESS = 'REGISTER_SOSMED_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS';
export const FACEBOOK_LOGIN_FAIL = 'FACEBOOK_LOGIN_FAIL';
export const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
export const GOOGLE_LOGIN_FAIL = 'GOOGLE_LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_LASTPRODUCTPAGE = 'SET_LASTPRODUCTPAGE';
export const FACEBOOK_NEW_REGISTER = 'FACEBOOK_NEW_REGISTER';
export const GOOGLE_NEW_REGISTER = 'GOOGLE_NEW_REGISTER';
export const VERIFY_REGISTER_SUCCESS = 'VERIFY_REGISTER_SUCCESS';
export const VERIFY_REGISTER_FAIL = 'VERIFY_REGISTER_FAIL';
export const CART_ADD_ITEM = 'CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM';
export const SET_LOADING = 'SET_LOADING';
export const CART_SET_PRODUCTS = 'CART_SET_PRODUCTS';
export const CART_ADD_SHIPPING = 'CART_ADD_SHIPPING';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';
export const CART_CLEAR_PRODUCTS = 'CART_CLEAR_PRODUCTS';
export const CART_CLEAR_SHIPPINGS = 'CART_CLEAR_SHIPPINGS';
export const WISHLIST_SET_COUNT = 'WISHLIST_SET_COUNT';
