import React, { useState, useContext, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";

import AlertContext from "../../../context/alert/alertContext";
import AuthContext from "../../../context/auth/authContext";

function ReferralBonusClaim() {
  const authContext = useContext(AuthContext);
  const { currentUser } = authContext;

  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const [redeemVal, setRedeemVal] = useState("");

  const [userData, setUserData] = useState({
    referralCode: "",
    totalReferralPoint: "",
    redeemSUccess: false,
    redeemPointHistory: [],
  });
  const {
    referralCode,
    totalReferralPoint,
    redeemSUccess,
    redeemPointHistory,
  } = userData;

  const [minRedeem, setMinRedeem] = useState(0);

  useEffect(() => {
    loadUser();
    loadConfiguration();
  }, [redeemSUccess]);

  async function loadUser() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/users/getuserdetail`
      );

      const history = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/users/getuserredeemhistory`
      );

      setUserData({
        referralCode: res.data.data.referralCode,
        totalReferralPoint: res.data.data.totalReferralPoint,
        redeemPointHistory: history.data.data,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function loadConfiguration() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/configuration/get`
      );
      setMinRedeem(res.data.data.minCashbackCollected);
    } catch (error) {
      console.log(error);
    }
  }

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  const onClickRedeem = () => {
    if (
      redeemVal &&
      redeemVal > 0 &&
      redeemVal <= totalReferralPoint &&
      redeemVal >= minRedeem
    ) {
      confirmAlert({
        title: "Sudah Yakin ?",
        message: `Sudah yakin mau ajukan redeem ${formatter.format(
          redeemVal
        )} ?`,
        buttons: [
          {
            label: "Ya",
            onClick: () => submitRedeem(),
          },
          {
            label: "Tidak",
            onClick: () => console.log("Cancel save..."),
          },
        ],
      });
    } else if (redeemVal > totalReferralPoint) {
      setAlert(
        `Nilai redeem maksimal ${formatter.format(totalReferralPoint)}`,
        "danger"
      );
    } else if (redeemVal < minRedeem) {
      setAlert(`Nilai redeem minimal ${formatter.format(minRedeem)}`, "danger");
    } else {
      setAlert("Nilai redeem tidak boleh 0 atau minus", "danger");
    }
  };

  async function submitRedeem() {
    let formData = new FormData();
    formData.append("redeemVal", redeemVal);

    try {
      await axios.put(
        `${process.env.REACT_APP_APIURL}api/v1/users/redeemPointRequest`,
        formData
      );

      setAlert(
        "Permintaan redeem berhasil dikirim. Silahkan menunggu proses transfer bonus.",
        "success"
      );

      setUserData({
        ...userData,
        redeemSUccess: true,
      });
      setRedeemVal("");
    } catch (err) {
      setAlert(err.response.data.error, "danger");
    }
  }

  if (currentUser && currentUser.role !== "partner") {
    return <Redirect to="/account/dashboard" />;
  } else {
    return (
      <>
        <div className="information-title">
          Referral Bonus Klaim (Referral Code: {referralCode})
        </div>
        <p>
          <strong>
            SALDO BONUS ANDA: {formatter.format(totalReferralPoint)}
          </strong>
          <br />
          Minimal saldo untuk redeem: {formatter.format(minRedeem)}
        </p>
        {totalReferralPoint >= minRedeem && (
          <div className="form-contact">
            <label>Redeem (Rp)</label>
            <br />
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                type="number"
                required
                placeholder="Masukan nilai..."
                style={{ padding: "0.5rem", marginRight: "10px" }}
                value={redeemVal}
                onChange={(e) => setRedeemVal(e.target.value)}
              />
              <button
                className="btn-regis"
                style={{ width: "100px", cursor: "pointer", padding: "0.5rem" }}
                onClick={onClickRedeem}
              >
                REDEEM
              </button>
            </div>
          </div>
        )}

        <h3>Redeem History</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Tanggal</th>
              <th>Nilai Redeem</th>
              <th>Status</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {redeemPointHistory &&
              redeemPointHistory.map((history, index) => {
                return (
                  <tr key={index}>
                    <th>{moment(history.redeemDate).format("DD MMM YYYY")}</th>
                    <th>{formatter.format(history.redeemValue)}</th>
                    <th>{history.status}</th>
                    <th>{history.note}</th>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </>
    );
  }
}

export default ReferralBonusClaim;
