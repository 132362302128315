import React, { useEffect, useState } from "react"
import axios from "axios"
import { EditorState, convertFromRaw } from "draft-js"
import { stateToHTML } from "draft-js-export-html"
import { Markup } from "interweave"
import ScheduleByProgram from "./ScheduleByProgram"

const ProgramDetail = ({ match }) => {
	const [program, setProgram] = useState({})
	const [intro, setIntro] = useState(EditorState.createEmpty())
	const [content, setContent] = useState(EditorState.createEmpty())

	useEffect(() => {
		loadProgram(match.params.alias)
		//eslint-disable-next-line
	}, [])

	async function loadProgram(alias) {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/programs/alias/${alias}`
			)

			setProgram(res.data.data)

			setContent(
				EditorState.createWithContent(
					convertFromRaw(JSON.parse(res.data.data.content))
				)
			)

			setIntro(
				EditorState.createWithContent(
					convertFromRaw(JSON.parse(res.data.data.introduction))
				)
			)
		} catch (err) {
			console.log(err.response.data, "danger")
		}
	}

	const introText = stateToHTML(intro.getCurrentContent())
	const contentText = stateToHTML(content.getCurrentContent())

	if (program) {
		return (
			<section className="py-2">
				<div className="container">
					<div className="row">
						<h1 className="sectionTitle">{program.name}</h1>
						<div className="about-section">
							<img
								className="img-service"
								src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.thumbnail}`}
								alt=""
							/>
							<div className="about-section-text">
								<Markup content={introText} />
							</div>
						</div>
						<div className="about-text">
							<Markup content={contentText} />
						</div>
						{program.video1 && (
							<div className="videoModul">
								<h2>VIDEO PENGENALAN MODUL:</h2>

								<div className="grid-video">
									{program.video1 && (
										<div className="text-center">
											<h2>PART 1</h2>
											<div>
												<video controls style={{ width: "100%" }}>
													<source
														src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.video1}`}
														type="video/mp4"
													/>
												</video>
											</div>
										</div>
									)}
									{program.video2 && (
										<div className="text-center">
											<h2>PART 2</h2>
											<div>
												<video controls style={{ width: "100%" }}>
													<source
														src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.video2}`}
														type="video/mp4"
													/>
												</video>
											</div>
										</div>
									)}
									{program.video3 && (
										<div className="text-center">
											<h2>PART 3</h2>
											<div>
												<video controls style={{ width: "100%" }}>
													<source
														src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.video3}`}
														type="video/mp4"
													/>
												</video>
											</div>
										</div>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<ScheduleByProgram program={program} />
			</section>
		)
	} else {
		return null
	}
}

export default ProgramDetail
