import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const About = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    loadPartners();
    //eslint-disable-next-line
  }, []);

  async function loadPartners() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/all/frontend`
      );

      setPartners(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <section className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            TENTANG <br /> AKADEMI NLP INDONESIA
          </h1>

          <div className="about-section-text">
            <p>
              TERIMA KASIH telah menjadi Mitra Premium INSPIRASI INDONESIA® dan
              telah menjadi bagian dari pertumbuhan kami selama lebih dari 14
              tahun menjadi salah satu Institusi Manajemen dan Pengembangan SDM
              terkemuka di Indonesia. Nama-nama seperti
              <span style={{ fontStyle: "italic" }}>
                {" "}
                NLP Indonesia®, The Society of NLP®, Debono Thinking System®,
                Paul Ekman InternationalTM{" "}
              </span>
              , adalah bagian dari keluarga besar INSPIRASI INDONESIA®, yang
              selama kemitraan bersama organisasi Anda menjadi jaminan kualitas
              dan kredibilitas program INSPIRASI INDONESIA®.{" "}
            </p>
            <p>
              Kompetensi, kepakaran, serta teknologi pemberdayaan diri tingkat
              internasional dari berbagai institusi tersebut, adalah yang kami
              terus-menerus kembangkan di Indonesia. Inilah yang mendorong kami
              untuk membangun wadah yang didedikasikan untuk para alumni kami
              yang telah mendapatkan sertifikasi kompetensi dan kepakaran
              internasionalnya, untuk berkarya sebagai Instruktur.{" "}
            </p>
          </div>
          <div className="about-text">
            <p>
              AKADEMI NLP INDONESIA<sup>TM</sup> adalah institusi yang kami
              resmikan untuk memperluas layanan pemberdayaan diri SDM dengan
              kompetensi dan kepakaran internasional tersebut. Semua Instruktur
              di bawah AKADEMI NLP INDONESIA<sup>TM</sup> telah disertifikasi
              secara internasional, untuk menjamin tidak saja level kedalaman
              teknologi pemberdayaan dirinya, juga skil eksepsional mereka untuk
              membawakan setiap program.{" "}
            </p>
            <p>
              Kami dengan gembira juga mengajak semua mitra kami, untuk bekerja
              sama lebih erat menjadi Corporate Business Partner dari AKADEMI
              NLP INDONESIA<sup>TM</sup>. Para Instruktur kelas dunia di AKADEMI
              NLP INDONESIA<sup>TM</sup> sudah tidak sabar ingin bisa ikut
              mendukung sukses organisasi Anda sebagai para mitra premium kami,
              melalui berbagai programnya.
            </p>
          </div>

          <div id="AboutHing" style={{ padding: "50px" }}></div>

          <h1 className="sectionTitle">
            TENTANG <br /> HINGDRANATA NIKOLAY
          </h1>
          <div className="about-section">
            <img
              className="img-service"
              src={
                process.env.PUBLIC_URL +
                "/assets/img/Tentang-Hingdranata-Nikolay.jpg"
              }
              alt=""
            />
            <div className="about-section-text">
              <p>
                HINGDRANATA NIKOLAY adalah CEO dan Master Trainer dari INSPIRASI
                INDONESIA, NLP INDONESIA dan AKADEMI NLP INDONESIA. Hing
                dianugerahkan oleh Dr.Richard Bandler di tahun 2012, sebagai
                Licensed Master Trainer of Neuro-Linguistic Programming pertama
                di Asia Tenggara. Ia adalah satu-satunya orang Indonesia yang
                dipercaya ikut memberikan pelatihan NLP bersama tim elit Master
                Trainer NLP seluruh dunia di Orlando, USA.
              </p>
              <p>
                Ia juga adalah Approved Trainer of Paul Ekman International
                pertama dan satu-satunya di Indonesia. Di bidang berpikir,
                problem solving, pembuatan keputusan dan kreativitas, Hing
                mewakili Dr.Edward De Bono sebagai Certified Instructor of
                Lateral Thinking dan Six Thinking Hats.
              </p>
              <p>
                Ia memfokuskan karyanya selama 12 tahun terakhir area kemampuan
                berpikir, kreativitas, daya pengaruh, dan kecerdasan emosional.
                Hing memiliki pengalaman lebih dari 20 di dunia bisnis praktis
                di berbagai industri, dari seorang salesman, bekerja keras
                sampai mencapai posisi manajemen tertinggi.
              </p>
              <p>
                Ia mengkombinasikan pengalamannya yang kaya, dengan konsep,
                riset, dan studi di Neuro-science, Social Psychology, dan Neuro
                Linguistic Programming. Partners dan klien dari berbagai level
                dan bidang mempercayakan kepada Hing untuk membantu meroketkan
                kinerja dan hasil mereka, melalui program Pelatihan, Coaching,
                serta konsultansi. Ratusan organisasi swasta dan pemerintahan
                mempercayakan Hing sebagai partner pengembangan organisasi dan
                SDM mereka. Ia bekerja sama dengan TV dan Radio, dan telah
                menerbitkan 3 buku best seller. Tahun 2013, ia membantu Timnas
                U23 merebut medali perak di Sea Games Myanmar, sebagai Pelatih
                Mental. Dr. Richard Bandler menyebutnya sebagai Master Trainer
                BERKELAS dan BERKUALITAS! Dr. Kevin Hogan menyebutnya sebagai
                PEMIKIR KELAS SATU!
              </p>
            </div>
          </div>
          <div className="about-text">
            <p>
              Hingdranata Nikolay memberikan bimbingan dan kontrol kualitas
              secara pribadi kepada Para Instruktur dibawah institusi AKADEMI
              NLP INDONESIA. Ia dan Dr. Richard Bandler sebagai pencipta NLP,
              memberikan sertifikasi instruktur AKADEMI NLP INDONESIA dan
              menjamin kualitas dan kompetensi kompetensi semua Instruktur di
              masing-masing bidang yang dibawakan.
            </p>
            <p>
              Variasi pengalaman ini memungkinkannya untuk mempunyai kekayaan
              dan kedalaman konsep dalam hubungan dengan manajemen,
              kepemimpinan, komunikasi, hubungan antar manusia, dan pengembangan
              manusia. Dipadu dengan minatnya yang sangat besar terhadap cara
              berpikir dan tingkah laku manusia, ia akhirnya memilih untuk
              meneruskan karir dan berprofesi di bidang manajemen dan
              pengembangan SDM.
            </p>
          </div>

          <div id="AboutIns" style={{ padding: "50px" }}></div>

          <h1 className="sectionTitle">
            TENTANG <br /> INSTRUKTUR BERLISENSI
          </h1>
          <div className="about-section-text">
            <p>
              Semua Instruktur di AKADEMI NLP INDONESIATM telah dipercaya
              sebagai pembicara di NLP INDONESIA CONFERENCE 2020. Mereka berada
              sepanggung dengan para Master Trainer NLP kelas dunia.{" "}
            </p>
            <p>
              HINGDRANATA NIKOLAY, sebagai Licensed Master Trainer of NLP
              pertama di Asia Tenggara, memastikan kualitas semua instruktur
              dengan bimbingan dan kontrol kualitas secara pribadi. Dan
              Dr.Richard Bandler sebagai pencipta NLP, menjamin kualitas mereka
              melalui sertifikasi dari beliau untuk pengakuan kompetensi semua
              Instruktur di masing-masing bidang yang dibawakan.{" "}
            </p>
          </div>
          <div className="about-text">
            <div className="teach">
              {partners.length > 0 &&
                partners.map((partner, index) => {
                  if (index < 4) {
                    return (
                      <div className="teach-1">
                        <Link to={`/instruktur/${partner.slug}`}>
                          <img
                            src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                            alt=""
                          />
                        </Link>
                        <p style={{ textTransform: "uppercase" }}>
                          {partner.name}
                        </p>
                      </div>
                    );
                  }
                })}
            </div>

            <div className="teach py-1">
              {partners.length > 0 &&
                partners.map((partner, index) => {
                  if (index > 3) {
                    return (
                      <div className="teach-1">
                        <Link to={`/instruktur/${partner.slug}`}>
                          <img
                            src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                            alt=""
                          />
                        </Link>
                        <p style={{ textTransform: "uppercase" }}>
                          {partner.name}
                        </p>
                      </div>
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
