import React, { useEffect, useState } from "react";
import axios from "axios";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Markup } from "interweave";

const Instruktur = ({ match }) => {
  const [partner, setPartner] = useState([]);
  const [about, setAbout] = useState(EditorState.createEmpty());

  useEffect(() => {
    loadPartner(match.params.alias);
    //eslint-disable-next-line
  }, [match.params.alias]);

  async function loadPartner(alias) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/alias/${alias}`
      );

      setPartner(res.data.data);

      setAbout(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(res.data.data.about))
        )
      );
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  let aboutText = stateToHTML(about.getCurrentContent());

  if (partner) {
    return (
      <section className="py-2">
        <div className="container">
          <div className="row">
            <h1 className="sectionTitle" style={{ textTransform: "uppercase" }}>
              {partner.name}
            </h1>
            <div className="about-section">
              <img
                className="img-service"
                src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.photo}`}
                alt=""
              />
              <div className="about-section-text">
                <Markup content={aboutText} />
              </div>
            </div>
            {partner.video && (
              <div className="videoIntro">
                <h2>VIDEO PERKENALAN :</h2>
                <video controls>
                  <source
                    src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.video}`}
                    type="video/mp4"
                  />
                </video>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
};

export default Instruktur;
