import React from "react";

import Slideshow from "./modules/Slideshow";
import Welcome from "./modules/Welcome";
import HomeSchedule from "./modules/HomeSchedule";
import HomeServices from "./modules/HomeServices";
import HomeProgram from "./modules/HomeProgram";
import HomeInstruktur from "./modules/HomeInstruktur";
import HomeTestimonies from "./modules/Hometestimonies";

const Home = () => {
  return (
    <>
      <Slideshow />
      <Welcome />
      <HomeSchedule />
      <HomeServices />
      <HomeProgram />
      <HomeInstruktur />
      <HomeTestimonies />
    </>
  );
};

export default Home;
