import React, { useEffect, useState } from "react"
import ScheduleItem from "./ScheduleItem"
import axios from "axios"
import { Link } from "react-router-dom"
import moment from "moment"

const HomeSchedule = () => {
	const [schedules, setSchedules] = useState([])

	useEffect(() => {
		loadSchedules()
		//eslint-disable-next-line
	}, [])

	async function loadSchedules() {
		try {
			const schedules = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/schedules/get/homepage`
			)

			const config = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/configuration/get`
			)

			const newSchedules = []

			const todayTimestamp = moment().format("x")

			let count = 1
			schedules.data.data.forEach((schedule) => {
				const eventdateTimestamp = moment(schedule.date).format("x")
				const dayDiff =
					(eventdateTimestamp - todayTimestamp) / (1000 * 60 * 60 * 24)

				if (
					schedule.stocks === 0 ||
					dayDiff <= config.data.data.normalpriceDaybefore
				) {
					schedule.isOpen = false
				} else {
					schedule.isOpen = true
					if (count < 4) {
						newSchedules.push(schedule)
					}
					count++
				}
			})

			if (newSchedules.length > 0) {
				setSchedules(newSchedules)
			}
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<section id="bg-grey" className="py-2">
			<div className="container">
				<div className="row">
					<h1 className="sectionTitle">
						JADWAL
						<br />
						KAMI
					</h1>
					<div className="column" id="programMendatang">
						<h2>Program Mendatang</h2>
						<p>
							Mari bergabung dengan kami dalam program yang berkelas dan
							berkualitas! Hadiahkan diri Anda dan orang-orang yang Anda cintai
							di salah satu kelas kami di bawah ini. Pastikan Anda cek
							kesesuaian waktu Anda dengan salah satu jadwal di bawah! Kami
							TIDAK PERNAH mengundurkan jadwal atau membatalkan pelaksanaan
							karena alasan jumlah peserta.
						</p>

						<Link to="/jadwal" className="view">
							LIHAT SEMUA
						</Link>
						{schedules.length > 0 &&
							schedules.map((schedule) => (
								<ScheduleItem key={schedule._id} schedule={schedule} />
							))}
					</div>
				</div>
			</div>
		</section>
	)
}

export default HomeSchedule
