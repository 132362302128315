import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

import AlertContext from '../context/alert/alertContext'
import AuthContext from '../context/auth/authContext'

const CourseCheckout = ({ match }) => {
	const alertContext = useContext(AlertContext)
	const { setAlert } = alertContext
	const authContext = useContext(AuthContext)
	const { isAuthenticated, currentUser } = authContext
	const [schedule, setSchedule] = useState('')
	const [speakers, setSpeakers] = useState('')
	const [program, setProgram] = useState('')
	const [user, setUser] = useState({
		name: '',
		email: '',
		handphone: '',
		role: '',
	})
	const [cartInfo, setCartInfo] = useState({
		unitPrice: '',
		quantity: 1,
		productAmount: '',
		participants: [],
		referralCode: '',
		voucherCode: '',
		voucherDiscAmount: '',
		redeemAvailable: '',
		redeemAmount: '',
		grandTotal: '',
		isEarlyBird: false,
		scheduleId: match.params.id,
	})
	const {
		unitPrice,
		quantity,
		productAmount,
		participants,
		referralCode,
		voucherCode,
		voucherDiscAmount,
		redeemAvailable,
		redeemAmount,
		grandTotal,
		isEarlyBird,
	} = cartInfo

	useEffect(() => {
		loadData()
		//eslint-disable-next-line
	}, [])

	async function loadData() {
		try {
			const config = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/configuration/get`
			)

			const schedule = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/schedules/${match.params.id}`
			)
			setSchedule(schedule.data.data)

			const userData = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/users/getuserdetail`
			)

			setUser({
				name: userData.data.data.name,
				email: userData.data.data.email,
				handphone: userData.data.data.handphone,
				role: userData.data.data.role,
			})

			const todayTimestamp = moment().format('x')
			//const eventdateTimestamp = moment(schedule.data.data.date).format('x')
			const earlyBirdLatestDateTimestamp = moment(
				schedule.data.data.earlyBirdLatestDate
			).format('x')

			if (earlyBirdLatestDateTimestamp >= todayTimestamp) {
				setCartInfo({
					...cartInfo,
					unitPrice: schedule.data.data.earlybirdPrice,
					productAmount: schedule.data.data.earlybirdPrice * 1,
					grandTotal: schedule.data.data.earlybirdPrice * 1,
					isEarlyBird: true,
					redeemAvailable: userData.data.data.totalReferralPoint,
					participants: [
						{
							name: userData.data.data.name,
							email: userData.data.data.email,
							handphone: userData.data.data.handphone,
						},
					],
				})
			} else {
				setCartInfo({
					...cartInfo,
					unitPrice: schedule.data.data.price,
					productAmount: schedule.data.data.price * 1,
					grandTotal: schedule.data.data.price * 1,
					isEarlyBird: false,
					redeemAvailable: userData.data.data.totalReferralPoint,
					participants: [
						{
							name: userData.data.data.name,
							email: userData.data.data.email,
							handphone: userData.data.data.handphone,
						},
					],
				})
			}

			const program = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/programs/${schedule.data.data.program}`
			)
			setProgram(program.data.data)

			let speakersData
			schedule.data.data.speakers.map(async (speaker) => {
				try {
					const res = await axios.get(
						`${process.env.REACT_APP_APIURL}api/v1/businesspartners/${speaker}`
					)
					if (speakersData) {
						speakersData = speakersData + ' & ' + res.data.data.name
					} else {
						speakersData = res.data.data.name
					}
					setSpeakers(speakersData)
				} catch (err) {
					console.log(err)
				}
			})
		} catch (err) {
			console.log(err)
		}
	}

	//check if user is authenticated, if not, then redirect to  login
	if (!isAuthenticated || !currentUser) {
		return <Redirect to="/login" />
	}

	const onChangeQty = (e) => {
		const participantsArr = []

		for (let i = 0; i < parseInt(e.target.value); i++) {
			if (i === 0) {
				participantsArr.push({
					name: user.name,
					email: user.email,
					handphone: user.handphone,
				})
			} else {
				participantsArr.push({
					name: '',
					email: '',
					handphone: '',
				})
			}
		}

		setCartInfo({
			...cartInfo,
			quantity: e.target.value,
			participants: participantsArr,
			productAmount: unitPrice * e.target.value,
			grandTotal: unitPrice * e.target.value,
			voucherCode: '',
			voucherDiscAmount: '',
			redeemAmount: '',
		})
		document.getElementById('boxKodeKupon').value = ''
		document.getElementById('redeemPointAmount').value = ''
	}

	const onAddReferralCode = async () => {
		const inputReferralCode = document.getElementById('addReferralCode').value

		if (inputReferralCode === '') {
			setAlert('Kode Referral harus diisi', 'danger')
		} else {
			//check if referralcode exist
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_APIURL}api/v1/users/checkreferralcode/${inputReferralCode}`
				)
				if (res.data.data === 1) {
					setCartInfo({
						...cartInfo,
						referralCode: inputReferralCode,
					})
					setAlert('Kode Referral berhasil ditambahkan', 'success')
				} else {
					setCartInfo({
						...cartInfo,
						referralCode: '',
					})

					setAlert('Kode Referral tidak sesuai', 'danger')
				}
			} catch (err) {
				setAlert(err.response.data, 'danger')
			}
		}
	}

	const onRedeemVoucher = async () => {
		if (voucherCode === '') {
			setAlert('Kode kupon harus diisi', 'danger')
		} else {
			//check if voucher exist
			try {
				const res = await axios.get(
					`${process.env.REACT_APP_APIURL}api/v1/vouchers/getcode/${voucherCode}`
				)
				if (res.data.data) {
					if (res.data.data.discountType === 'amount') {
						setCartInfo({
							...cartInfo,
							voucherCode: res.data.data.code,
							voucherDiscAmount: res.data.data.discountAmount,
							grandTotal:
								productAmount - res.data.data.discountAmount - redeemAmount,
						})
					} else {
						setCartInfo({
							...cartInfo,
							voucherCode: res.data.data.code,
							voucherDiscAmount:
								(productAmount * res.data.data.discountAmount) / 100,
							grandTotal:
								productAmount -
								(productAmount * res.data.data.discountAmount) / 100 -
								redeemAmount,
						})
					}

					setAlert('Kode kupon berhasil digunakan', 'success')
				} else {
					setCartInfo({
						...cartInfo,
						voucherCode: '',
						voucherDiscAmount: '',
						grandTotal: productAmount - redeemAmount,
					})

					setAlert('Kode kupon tidak sesuai', 'danger')
				}
			} catch (err) {
				setAlert(err.response.data, 'danger')
			}
		}
	}

	const onRedeemPoint = async () => {
		const redeemValue = parseInt(
			document.getElementById('redeemPointAmount').value
		)
		if (redeemValue === '' || redeemValue == 0) {
			setAlert('Jumlah redeem harus diisi', 'danger')
		} else if (redeemValue < 0) {
			setAlert('Jumlah redeem harus diatas 0', 'danger')
		} else {
			if (parseInt(redeemValue) <= parseInt(redeemAvailable)) {
				setCartInfo({
					...cartInfo,
					redeemAmount: redeemValue,
					grandTotal: productAmount - redeemValue - voucherDiscAmount,
				})
				setAlert('Redeem berhasil digunakan', 'success')
			} else {
				setCartInfo({
					...cartInfo,
					redeemAmount: '',
					grandTotal: productAmount - voucherDiscAmount,
				})

				setAlert(
					`Nilai redeem maksimal ${formatter.format(redeemAvailable)}`,
					'danger'
				)

				document.getElementById('redeemPointAmount').value = ''
			}
		}
	}

	const onChangeParticipant = (e, index) => {
		const newParticipants = participants

		switch (e.target.id) {
			case 'participantName':
				newParticipants[index].name = e.target.value
				break
			case 'participantEmail':
				newParticipants[index].email = e.target.value
				break
			case 'participantHp':
				newParticipants[index].handphone = e.target.value
				break
			default:
				break
		}

		setCartInfo({
			...cartInfo,
			participants: newParticipants,
		})
	}

	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})

	let participantsEmpty = 'no'
	//check if participants is not empty
	participants.forEach((item) => {
		if (!item.name || !item.email || !item.handphone) {
			participantsEmpty = 'yes'
			return false
		}
	})

	return (
		<section className="py-2">
			<div className="container">
				<div className="row">
					<h1 className="sectionTitle">Checkout as member</h1>
					<div className="layanan-detail">
						<div id="bg-grey" className="card my-1 checkoutParticipantBlock">
							<div className="form-contact" style={{ padding: '10px' }}>
								<label>Pilih Jumlah Peserta</label>
								<br />
								<div className="cartRedeemSection">
									<select
										className="wid-100"
										required
										value={quantity}
										onChange={onChangeQty}
									>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
										<option value="5">5</option>
										<option value="6">6</option>
										<option value="7">7</option>
										<option value="8">8</option>
										<option value="9">9</option>
										<option value="10">10</option>
									</select>
								</div>
							</div>

							<div className="form-contact" style={{ padding: '10px' }}>
								{participants.length > 0 &&
									participants.map((person, index) => {
										return (
											<>
												<label>Peserta {index + 1}</label>
												<br />
												<div
													style={{
														display: 'grid',
														gridTemplateColumns: '1fr 1fr 1fr',
														gridGap: '10px',
														marginBottom: '10px',
													}}
												>
													<input
														className="wid-40"
														type="text"
														id="participantName"
														style={{ width: '100%' }}
														defaultValue={person.name}
														placeholder="Nama Peserta..."
														required
														onChange={(e) => onChangeParticipant(e, index)}
													/>
													<input
														className="wid-40"
														type="text"
														id="participantEmail"
														style={{ width: '100%' }}
														placeholder="Email Peserta..."
														defaultValue={person.email}
														onChange={(e) => onChangeParticipant(e, index)}
													/>
													<input
														className="wid-40"
														id="participantHp"
														type="text"
														style={{ width: '100%' }}
														placeholder="Handphone Peserta..."
														defaultValue={person.handphone}
														onChange={(e) => onChangeParticipant(e, index)}
													/>
												</div>
											</>
										)
									})}
							</div>
						</div>
						<div id="bg-grey" className="card my-1">
							<div id="jadwalProgram2">
								<div className="jadwalName">
									<div>
										<img
											src={`${process.env.REACT_APP_APIURL}uploads/schedules/${schedule.thumbnail}`}
											alt=""
										/>
									</div>
									<div>
										{speakers && <p>{speakers}</p>}
										{program && <h3>{program.name}</h3>}
									</div>
								</div>
								<div className="jadwalDate">
									<p>Tanggal</p>
									<h3>
										{moment(schedule.date).format('ddd DD MMM YYYY')} s/d{' '}
										{moment(schedule.endDate).format('ddd DD MMM YYYY')}
									</h3>
									<h3>
										{schedule.startTime}-{schedule.endTime} WIB
									</h3>
								</div>
								<div className="jadwalDate">
									<p>Investasi {isEarlyBird && 'Early Bird'}</p>
									<h3>{formatter.format(unitPrice)} /tiket</h3>
								</div>
								<div className="jadwalDate">
									<p>Sub Total {isEarlyBird && 'Early Bird'}</p>
									<h3>{formatter.format(productAmount)}</h3>
									<p>Total Tiket: {quantity}</p>
								</div>
							</div>

							{referralCode && (
								<div
									id="jadwalProgram2"
									style={{ borderTop: '1px solid #b2afaf', paddingTop: '10px' }}
								>
									<div className="jadwalName">
										<div></div>
										<div></div>
									</div>
									<div className="jadwalDate">
										<h3>Kode Referral</h3>
									</div>
									<div className="jadwalDate">
										<h3>{referralCode}</h3>
									</div>
								</div>
							)}

							{voucherDiscAmount && (
								<div
									id="jadwalProgram2"
									style={{ borderTop: '1px solid #b2afaf', paddingTop: '10px' }}
								>
									<div className="jadwalName">
										<div></div>
										<div></div>
									</div>
									<div className="jadwalDate">
										<h3>Diskon Kupon</h3>
									</div>
									<div className="jadwalDate">
										<h3>{formatter.format(voucherDiscAmount)}</h3>
									</div>
								</div>
							)}

							{redeemAmount && (
								<div
									id="jadwalProgram2"
									style={{ borderTop: '1px solid #b2afaf', paddingTop: '10px' }}
								>
									<div className="jadwalName">
										<div></div>
										<div></div>
									</div>
									<div className="jadwalDate">
										<h3>Diskon Redeem</h3>
									</div>
									<div className="jadwalDate">
										<h3>{formatter.format(redeemAmount)}</h3>
									</div>
								</div>
							)}

							<div
								id="jadwalProgram2"
								style={{ borderTop: '1px solid #b2afaf', paddingTop: '10px' }}
							>
								<div className="jadwalName">
									<div></div>
									<div></div>
								</div>
								<div className="jadwalDate">
									<h3>TOTAL</h3>
								</div>
								<div className="jadwalDate">
									<h3>{formatter.format(grandTotal)}</h3>
								</div>
							</div>
						</div>

						<div id="bg-grey" className="card my-1 checkoutVoucherBlock">
							<div className="form-contact" style={{ padding: '10px' }}>
								<label>Kode Voucher</label>
								<br />
								<div className="cartRedeemSection">
									<input
										className="wid-40"
										type="text"
										id="boxKodeKupon"
										style={{ width: '100%' }}
										placeholder="Masukan Kode Voucher..."
										onChange={(e) =>
											setCartInfo({ ...cartInfo, voucherCode: e.target.value })
										}
									/>
									<button
										className="btn-regis cartRedeemButton"
										onClick={onRedeemVoucher}
									>
										GUNAKAN
									</button>
								</div>
							</div>

							<div className="form-contact" style={{ padding: '10px' }}>
								<label>Kode Referal</label>
								<br />
								<div className="cartRedeemSection">
									<input
										className="wid-40"
										type="text"
										name="referralcode"
										style={{ width: '100%' }}
										placeholder="Masukan Kode Referal..."
										id="addReferralCode"
									/>
									<button
										className="btn-regis cartRedeemButton"
										onClick={onAddReferralCode}
									>
										GUNAKAN
									</button>
								</div>
							</div>

							{user.role && user.role === 'partner' && (
								<div className="form-contact" style={{ padding: '10px' }}>
									<label>
										Redeem (Tersedia {formatter.format(redeemAvailable)})
									</label>
									<br />
									<div className="cartRedeemSection">
										<input
											className="wid-40"
											type="number"
											style={{ width: '100%' }}
											placeholder="Masukan redeem point..."
											id="redeemPointAmount"
										/>
										<button
											className="btn-regis cartRedeemButton"
											onClick={onRedeemPoint}
										>
											REDEEM
										</button>
									</div>
								</div>
							)}
						</div>
						{participantsEmpty === 'no' && (
							<div className="form-control" style={{ textAlign: 'right' }}>
								<Link
									to={{ pathname: '/checkout-payment', cartInfo }}
									style={{ width: '300px', cursor: 'pointer' }}
									className="btn-regis"
								>
									LANJUT KE PEMBAYARAN
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
			<br />
			<br />
		</section>
	)
}

export default CourseCheckout
