import React, { useEffect, useState } from "react";
import axios from "axios";
import HomeProgramItem from "./HomeProgramItem";

const HomeProgram = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    loadPrograms();
    //eslint-disable-next-line
  }, []);

  async function loadPrograms(pageNumber = 1) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/programs/all/${pageNumber}`
      );

      setPrograms(res.data.data);
    } catch (err) {
      // console.log(err.response.data, "danger");
    }
  }

  return (
    <section id="bg-grey" className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            PROGRAM <br /> KAMI
          </h1>
          <div className="program">
            {programs.length > 0 &&
              programs.map((program) => (
                <HomeProgramItem key={program._id} program={program} />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeProgram;
