import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const HomeInstruktur = () => {
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    loadPartners();
    //eslint-disable-next-line
  }, []);

  async function loadPartners() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/all/frontend`
      );

      setPartners(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <section className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            INSTRUKTUR
            <br />
            KAMI
          </h1>

          <div className="teach">
            {partners.length > 0 &&
              partners.map((partner, index) => {
                if (index < 4) {
                  return (
                    <div className="teach-1">
                      <Link to={`/instruktur/${partner.slug}`}>
                        <img
                          src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                          alt=""
                        />
                      </Link>
                      <p style={{ textTransform: "uppercase" }}>
                        {partner.name}
                      </p>
                    </div>
                  );
                }
              })}
          </div>

          <div className="teach py-1">
            {partners.length > 0 &&
              partners.map((partner, index) => {
                if (index > 3) {
                  return (
                    <div className="teach-1">
                      <Link to={`/instruktur/${partner.slug}`}>
                        <img
                          src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                          alt=""
                        />
                      </Link>
                      <p style={{ textTransform: "uppercase" }}>
                        {partner.name}
                      </p>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeInstruktur;
