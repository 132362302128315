import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { EditorState, convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { Markup } from 'interweave'
import { Link } from 'react-router-dom'
import moment from 'moment'

const ScheduleDetail = ({ match }) => {
	const [schedule, setSchedule] = useState('')
	const [speakers, setSpeakers] = useState('')
	const [program, setProgram] = useState('')
	const [intro, setIntro] = useState(EditorState.createEmpty())
	const [content, setContent] = useState(EditorState.createEmpty())
	const [configuration, setConfiguration] = useState('')
	const { normalpriceDaybefore, earlybirdpriceDaybefore } = configuration

	useEffect(() => {
		loadData()
		loadConfiguration()
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		localStorage.setItem('lastSchedulePageVisited', `jadwal/${match.params.id}`)
		//eslint-disable-next-line
	}, [])

	async function loadData() {
		try {
			const schedule = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/schedules/${match.params.id}`
			)
			setSchedule(schedule.data.data)

			const program = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/programs/${schedule.data.data.program}`
			)
			setProgram(program.data.data)

			setContent(
				EditorState.createWithContent(
					convertFromRaw(JSON.parse(program.data.data.content))
				)
			)

			setIntro(
				EditorState.createWithContent(
					convertFromRaw(JSON.parse(program.data.data.introduction))
				)
			)

			let speakersData
			schedule.data.data.speakers.map(async (speaker) => {
				try {
					const res = await axios.get(
						`${process.env.REACT_APP_APIURL}api/v1/businesspartners/${speaker}`
					)
					if (speakersData) {
						speakersData = speakersData + ' & ' + res.data.data.name
					} else {
						speakersData = res.data.data.name
					}
					setSpeakers(speakersData)
				} catch (err) {
					console.log()
				}
			})
		} catch (err) {
			console.log()
		}
	}

	async function loadConfiguration() {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/configuration/get`
			)
			setConfiguration(res.data.data)
		} catch (err) {
			console.log()
		}
	}

	const introText = stateToHTML(intro.getCurrentContent())
	const contentText = stateToHTML(content.getCurrentContent())

	const formatter = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
		maximumFractionDigits: 0,
		minimumFractionDigits: 0,
	})

	const todayTimestamp = moment().format('x')
	const eventdateTimestamp = moment(schedule.date).format('x')
	const dayDiff = Math.ceil(
		(eventdateTimestamp - todayTimestamp) / (1000 * 60 * 60 * 24)
	)
	const earlyBirdClosedate = moment(schedule.earlyBirdLatestDate).format(
		'DD MMM YYYY'
	)

	// const earlyBirdClosedate = moment(
	// 	eventdateTimestamp - earlybirdpriceDaybefore * 24 * 60 * 60 * 1000
	// ).format('DD MMM YYYY')

	return (
		<section className="py-2">
			<div className="container">
				<div className="row">
					<h1 className="sectionTitle">
						{program.name}
						<br />
						<span style={{ fontSize: '1.4rem' }}>Bersama {speakers}</span>
					</h1>

					<div className="layanan-info" style={{ textAlign: 'center' }}>
						<img
							style={{ margin: '0 auto', maxWidth: '500px' }}
							className="img-service"
							src={`${process.env.REACT_APP_APIURL}uploads/schedules/${schedule.thumbnail}`}
							alt=""
						/>
						<br />
						<br />
					</div>

					<div className="layanan-detail">
						{/* <img
							style={{ width: "300px", float: "left" }}
							className="img-service"
							src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.thumbnail}`}
							alt=""
						/>{" "} */}
						<Markup content={introText} />
						<Markup content={contentText} />
					</div>

					<br />
					<h1 style={{ textAlign: 'center' }}>
						Dan yang perlu Anda lakukan adalah memulai! Sekarang juga!
					</h1>

					<div id="bg-grey" className="card my-1">
						<div id="jadwalProgram3">
							<div>
								<img
									src={`${process.env.REACT_APP_APIURL}uploads/schedules/${schedule.thumbnail}`}
									alt=""
								/>
							</div>
							<div>
								<p>Instruktur</p>
								<br />
								{speakers && (
									<p>
										<strong>{speakers}</strong>
									</p>
								)}
							</div>
							<div className="jadwalDate">
								<p>Tanggal Event</p>
								<br />
								<h3>
									{moment(schedule.date).format('ddd DD MMM YYYY')} s/d{' '}
									{moment(schedule.endDate).format('ddd DD MMM YYYY')}
								</h3>
								<h3>
									{schedule.startTime}-{schedule.endTime} WIB
								</h3>
							</div>
							<div className="jadwalDate">
								<p>
									Investasi Early Bird
									<br />
									last {earlyBirdClosedate}
								</p>
								<h3>{formatter.format(schedule.earlybirdPrice)}</h3>
							</div>
							<div className="jadwalDate">
								<p>
									Investasi Normal
									<br />
									<br />
								</p>
								<h3>{formatter.format(schedule.price)}</h3>
							</div>
							<div className="jadwalDate">
								<p>Tersisa</p>
								<br />
								{schedule.stocks === 0 ? <h3>SOLD</h3> : <h3>OPEN</h3>}
								{/* {schedule.stocks === 0 || dayDiff <= normalpriceDaybefore ? (
									<h3>SOLD</h3>
								) : (
									<h3>OPEN</h3>
								)} */}
							</div>
						</div>
					</div>

					<div className="layanan-detail">
						{schedule.stocks === 0 ? null : (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Link
									to={`/checkout-program/${schedule._id}`}
									className="btn-regis"
									style={{
										display: 'block',
										width: '300px',
										textAlign: 'center',
										fontSize: '16px',
										marginRight: '50px',
									}}
								>
									CHECKOUT <i className="fa fa-arrow-right"></i>
								</Link>
								<Link
									to={`/checkout-program-guest/${schedule._id}`}
									className="btn-regis"
									style={{
										display: 'block',
										width: '300px',
										textAlign: 'center',
										fontSize: '16px',
									}}
								>
									CHECKOUT AS GUEST <i className="fa fa-user"></i>
								</Link>
							</div>
						)}

						{/* {schedule.stocks === 0 || dayDiff <= normalpriceDaybefore ? null : (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Link
									to={`/checkout-program/${schedule._id}`}
									className="btn-regis"
									style={{
										display: 'block',
										width: '300px',
										textAlign: 'center',
										fontSize: '16px',
										marginRight: '50px',
									}}
								>
									CHECKOUT <i className="fa fa-arrow-right"></i>
								</Link>
								<Link
									to={`/checkout-program-guest/${schedule._id}`}
									className="btn-regis"
									style={{
										display: 'block',
										width: '300px',
										textAlign: 'center',
										fontSize: '16px',
									}}
								>
									CHECKOUT AS GUEST <i className="fa fa-user"></i>
								</Link>
							</div>
						)} */}
					</div>
				</div>
			</div>
			<br />
		</section>
	)
}

export default ScheduleDetail
