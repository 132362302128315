import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'

const ScheduleItem = ({ background, schedule }) => {
	const [speakers, setSpeakers] = useState('')
	const [program, setProgram] = useState('')

	useEffect(() => {
		loadData()
		//eslint-disable-next-line
	}, [])

	async function loadData() {
		try {
			const res = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/programs/${schedule.program}`
			)
			setProgram(res.data.data)

			let speakersData
			schedule.speakers.map(async (speaker) => {
				try {
					const res = await axios.get(
						`${process.env.REACT_APP_APIURL}api/v1/businesspartners/${speaker}`
					)
					if (speakersData) {
						speakersData = speakersData + ' & ' + res.data.data.name
					} else {
						speakersData = res.data.data.name
					}
					setSpeakers(speakersData)
				} catch (err) {
					console.log()
				}
			})
		} catch (err) {
			console.log()
		}
	}

	return (
		<div id={background} className="card my-1">
			<div id="jadwalProgram">
				<div className="jadwalName">
					<div>
						<img
							src={`${process.env.REACT_APP_APIURL}uploads/schedules/${schedule.thumbnail}`}
							alt=""
						/>
					</div>
					<div>
						{speakers && <p>{speakers}</p>}
						{program && <h3>{program.name}</h3>}
					</div>
				</div>
				<div className="jadwalDate">
					<p>Tanggal</p>
					<h3>
						{moment(schedule.date).format('ddd DD MMM YYYY')} s/d{' '}
						{moment(schedule.endDate).format('ddd DD MMM YYYY')}
					</h3>
					<h3>
						{schedule.startTime}-{schedule.endTime} WIB
					</h3>
				</div>
				<div className="jadwalCapacity">
					<p>Tersisa</p>
					{!schedule.isOpen ? <h3>SOLD</h3> : <h3>OPEN</h3>}
				</div>
				<div className="jadwalBtn">
					{schedule.isOpen && (
						<Link to={`/jadwal/${schedule._id}`} className="btn-jadwal">
							GABUNG
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}

export default ScheduleItem
