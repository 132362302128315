import React from "react";
import { Link } from "react-router-dom";

const HomeProgramItem = ({ program }) => {
  return (
    <div className="card card-program">
      <img
        className="img-service"
        src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.thumbnail}`}
        alt=""
      />
      <div className="text-center">
        <h2 style={{ textTransform: "uppercase" }}>{program.name}</h2>
        <p>{program.opening}</p>
        <div className="btn-service btn-program py-2">
          <Link to={`/program/${program.slug}`}>LIHAT</Link>
        </div>
      </div>
    </div>
  );
};

export default HomeProgramItem;
