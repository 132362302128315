import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

function CoursePaymentGuest({ location }) {
  
  const [espayData, setEspayData] = useState({
    key: "",
    paymentId: "",
    backUrl: `${process.env.REACT_APP_APIURL}thankyou`,
    display: "select",
  });

  useEffect(() => {
    createNewOrder();
    //eslint-disable-next-line
  }, []);

  const [orderData, setOrderData] = useState(""); 

  async function createNewOrder() {
    const URL = `${process.env.REACT_APP_APIURL}api/v1/orders/submit/guest`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let formData = {};
    formData.cartInfo = location.cartInfo;

    try {
      const order = await axios.post(URL, formData, config);
      setOrderData(order.data.data);

      //initialize espay
      const key =  process.env.REACT_APP_ESPAY_API_KEY;

      setEspayData({
        ...espayData,
        key,
        paymentId: order.data.data._id,
      });

      const sgoPlusIframe = document.getElementById("sgoplus-iframe");

      if (sgoPlusIframe !== null)
        sgoPlusIframe.src = window.SGOSignature.getIframeURL({
          ...espayData,
          key,
          paymentId: order.data.data._id,
        });
      window.SGOSignature.receiveForm();
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  if(location.cartInfo && location.cartInfo.grandTotal) {
    return (
      <section className="py-2">
        <div className="container">
          <div className="row">
            <h1 className="sectionTitle">Pembayaran</h1>
            <div className="layanan-detail">
              <div id="bg-grey" className="card my-1">
                <h3>
                  Pembayaran {formatter.format(location.cartInfo.grandTotal)}{" "}
                </h3>
                <p>Silahkan memilih metode pembayaran dan klik SUBMIT untuk melanjutkan. Setelah submit, silahkan menunggu untuk diarahkan ke portal pembayaran.</p>
                <iframe id="sgoplus-iframe"></iframe>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
      </section>
    );
  } else {
    return null;
  }

  
}

export default CoursePaymentGuest;