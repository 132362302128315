import React, {useState, useEffect} from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const SmartSelling = () => {

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    loadPartners();
    //eslint-disable-next-line
  }, []);

  async function loadPartners() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/all/frontend`
      );

      setPartners(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <section className="py-2" id="layananDetail">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">SMART SELLING FROM HOME</h1>
          <div className="layanan layanan-intro">
            <img
              className="img-service"
              src={`${process.env.REACT_APP_APIURL}uploads/programs/thumbnail_smart-selling.jpg`}
              alt=""
            />
            <div className="layanan-info"></div>
          </div>

          <div className="layanan-detail">
            <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Tujuan</p>
            <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
              <li>Membangun kepercayaan diri dan jiwa pebisnis mandiri dari rumah</li>
              <li>Mampu set up lingkungan kerja yang productif untuk berjualan dan berbisnis di rumah</li>
              <li>Mampu mendesain sendiri berbagai materi penjualan melalui media online</li>
            </ul>  
          </div>
          <div style={{display:'grid', gridTemplateColumns:'1fr 1fr', gridGap:'1rem', paddingTop:'30px'}}>
            <div>
              <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Materi</p>
              <p><strong>SELLING IDEAS</strong></p>
              <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
                <li>Bagaimana jeli melihat potensi bisnis dari masalah</li>
                <li>Menentukan produk/jasa yang tepat untuk Anda</li>
              </ul>   
              <br />

              <p><strong>COFFEE TABLE SELLING</strong></p>
              <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
                <li>Bagaimana memulai dari rumah</li>
                <li>Tips ritual agar produktif dan positif di rumah</li>
                <li>Bagaimana 5 langkah mempresentasikan produk/jasa</li>
              </ul>   
              <br />

              <p><strong>COPYWRITING</strong></p>
              <ul style={{paddingLeft:'20px', listStyleType:'circle'}}>
                <li>3 Tipe customer dan teknik menjual yang tepat untuk mereka</li>
                <li>Bagaimana menulis sendiri teks iklan yang menarik</li>
                <li>Menggunakan template copywriting</li>
              </ul>   
              <br />

              <div className="btn-service btn-program py-2" style={{position:'static'}}>
                <Link to="/kontak">INFORMASI LEBIH LANJUT</Link>
              </div>
            </div>
            <div>
              <p style={{width:'100%', color: '#bb2024', background:'#eee', fontWeight:'bold', fontSize:'1.2rem', padding:'10px 20px'}}>Format Program<br /><span style={{color:'black'}}>2x90 Menit</span></p>
              <br />
              <div style={{width:'100%', background:'#eee', padding:'10px 20px'}}>
                  <p style={{color: '#bb2024', fontSize:'1.2rem', fontWeight:'bold'}}>Trainer</p>
                  <br />
                  <div style={{display:'grid', gridTemplateColumns:'1fr 1fr 1fr', gridGap:'1rem' }}>
                  {partners.length > 0 &&
              partners.map((partner, index) => {
                return (
                  <div className="teach-1">
                   <Link to={`/instruktur/${partner.slug}`}>
                      <img
                        src={`${process.env.REACT_APP_APIURL}uploads/businesspartners/${partner.thumbnail}`}
                        alt=""
                      />
                    </Link>
                    <p style={{ textTransform: "uppercase", borderBottom:'none', color:'black', fontWeight:'normal', fontSize:'0.9rem' }}>
                      {partner.name}
                    </p>
                  </div>
                );
              })}


                  </div>
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </section>
  );
};

export default SmartSelling;