import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Link } from "react-router-dom";
import { Markup } from "interweave";

const ProgramItem = ({ program }) => {
  const [intro, setIntro] = useState(EditorState.createEmpty());

  useEffect(() => {
    setIntro(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(program.introduction))
      )
    );
    //eslint-disable-next-line
  }, []);

  let introText = stateToHTML(intro.getCurrentContent());

  return (
    <div className="layanan">
      <img
        className="img-service"
        src={`${process.env.REACT_APP_APIURL}uploads/programs/${program.thumbnail}`}
        alt=""
      />
      <div className="layanan-info">
        <h2>{program.name}</h2>
        <Markup content={introText} />
        <div className="read-more">
          <Link to={`/program/${program.slug}`}>SELENGKAPNYA</Link>
        </div>
      </div>
    </div>
  );
};

export default ProgramItem;
