import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Link } from "react-router-dom";
import { Markup } from "interweave";

const HomeServiceItem = ({ service }) => {
  const [intro, setIntro] = useState(EditorState.createEmpty());

  useEffect(() => {
    setIntro(
      EditorState.createWithContent(
        convertFromRaw(JSON.parse(service.introduction))
      )
    );
    //eslint-disable-next-line
  }, []);

  let introText = stateToHTML(intro.getCurrentContent());

  return (
    <div id="bg-grey" className="card">
      <img
        className="img-service"
        src={`${process.env.REACT_APP_APIURL}uploads/services/${service.thumbnail}`}
        alt=""
      />
      <div className="text-center">
        <h2 style={{ textTransform: "uppercase" }}>{service.name}</h2>
        <Markup content={introText} />
        <div className="btn-service py-2">
          <Link to={`/layanan/${service.slug}`}>LIHAT</Link>
        </div>
      </div>
    </div>
  );
};

export default HomeServiceItem;
