import React from "react";

const Hometestimonies = () => {
  return (
    <section id="bg-grey" className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">TESTIMONI<br />KAMI</h1>
          <div className="testimoni">
            <div className="testimoniVideo">
              <video controls style={{width:'100%'}}>
                <source
                  src={`${process.env.REACT_APP_APIURL}uploads/testimoni.mp4`}
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="card">
              <div className="testi-card">
                <span><img
                    src={process.env.PUBLIC_URL + "/assets/img//kutip-atas.jpg"}
                    style={{ width: "50px" }}
                    alt=""
                  /></span>
                <blockquote>				
                  Thanks for the class. Enjoyed a lot ! Jadi tambah semangat
                </blockquote>
                <span className="kutip-bawah">
                <img
                    src={
                      process.env.PUBLIC_URL + "/assets/img//kutip-bawah.jpg"
                    }
                    style={{ width: "50px" }}
                    alt=""
                  /></span>
              </div>
              <div className="testiName">
                <h5>-Gita Djambek</h5>
              </div>
            </div>
            <div className="card">
              <div className="testi-card">
                <span><img
                    src={process.env.PUBLIC_URL + "/assets/img//kutip-atas.jpg"}
                    style={{ width: "50px" }}
                    alt=""
                  /></span>
                <blockquote>				
                  So happy to attend Creative Thingking Class from Akademi NLP
                  Indonesia. Learning to think creatively and such an eye opener
                  insights from the class. Learning new ways on how to get more
                  ideas. So never stop learning.
                </blockquote>
                <span className="kutip-bawah"><img
                    src={
                      process.env.PUBLIC_URL + "/assets/img//kutip-bawah.jpg"
                    }
                    style={{ width: "50px" }}
                    alt=""
                  /></span>
              </div>
              <div className="testiName">
                <h5>-Sarine</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hometestimonies;
