import React from "react";

const Welcome = () => {
  return (
    <section id="homeNewIn" className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            SELAMAT DATANG DI
            <br />
            AKADEMI NLP INDONESIA
          </h1>
          <div className="productItem">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/img/Selamat-Datang-Hingdranata-Nikolay.jpg"
              }
              alt="Welcome"
            />
            <div className="productItemText">
              <p>
                Terima kasih telah memilih Akademi NLP Indonesia sebagai partner
                pemberdayaan dan pengembangan diri Anda dan organisasi Anda!
                Untuk pertamakalinya di dunia Neuro-Linguistic Programming
                (NLP), Dr.Richard Bandler (pencipta NLP) memberikan restu resmi
                kepada Instruktur skil khusus dalam dunia NLP. Dan dengan senang
                hati, saya sampaikan bahwa para Instruktur yang tergabung dalam
                Akademi NLPIndonesia, kompetensinya telah diakui oleh Dr.
                Richard Bandler!
              </p>
              <p>
                Akademi NLP Indonesia menyambut Anda dengan antusiasme luar
                biasa untuk menentukan bersama Anda berbagai tools yang bisa
                membantu sukses dan bahagia Anda, baik sebagai pribadi,
                profesional, atau pebisnis! Adalah sebuah kehormatan dan
                kebahagiaan bagi Akademi NLP Indonesia untuk bermitra dengan
                Anda untuk membangun sukses dan bahagia Anda dan
                organisasi/bisnis Anda!
              </p>
              <h4>
                Hingdranata Nikolay <br />
                Licensed Master Trainer of NLP
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Welcome;
