import React, { useEffect, useState } from "react";
import axios from "axios";
import AkunProgramItem from "./AkunProgramItem";

const AkunProgram = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    loadPrograms();
    //eslint-disable-next-line
  }, []);

  async function loadPrograms(pageNumber = 1) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/programs/all/${pageNumber}`
      );

      setPrograms(res.data.data);
    } catch (err) {
      // console.log(err.response.data, "danger");
    }
  }

  return (
    <>
    <h1>Program</h1>
    <hr />
    <h3>Program yang tersedia untuk Anda.</h3>
    <div className="program">
      {programs.length > 0 &&
        programs.map((program) => (
          <AkunProgramItem key={program._id} program={program} />
        ))}
    </div>
    </>
  );
};

export default AkunProgram;