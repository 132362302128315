import React from "react";

const Contact = () => {
  return (
    <section className="py-2">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">
            HUBUNGI
            <br /> KAMI
          </h1>
          <p>
            Tel: (021) 3000 3614
            <br />
            Email: info@akademinlpindonesia.co.id
          </p>
          <p>
            Kami akan sangat senang bisa berbicara dengan Anda. Silahkan
            menghubungi nomor kami di (021) 3000 3614, atau silahkan mengisi
            formulir di bawah ini,dan staf kami akan menghubungi Anda.
          </p>

          <div className="contact">
            <form>
              <div className="form-contact">
                <label>Nama Lengkap</label>
                <br />
                <input className="wid-40" type="text" name="nama" required />
              </div>
              <div className="form-contact">
                <label>Kontak Atas Nama</label>
                <br />
                <input type="radio" name="pribadi" value="pribadi" />{" "}
                <span>Pribadi</span>
                <br />
                <input type="radio" name="perusahaan" value="perusahaan" />{" "}
                <span>Perusahaan</span>
              </div>

              <div className="contact-grid">
                <div className="form-contact">
                  <label>Jabatan</label>
                  <br />
                  <input
                    className="wid-80"
                    type="text"
                    name="jabatan"
                    required
                  />
                </div>
                <div className="form-contact">
                  <label>Perusahaan</label>
                  <br />
                  <input
                    className="wid-80"
                    type="text"
                    name="name_perusahaan"
                    required
                  />
                </div>
                <div className="form-contact">
                  <label>Nomor Telepon Pribadi/Whatsapp</label>
                  <br />
                  <input className="wid-80" type="text" name="no_wa" required />
                </div>
                <div className="form-contact">
                  <label>Nomor Telepon Kantor</label>
                  <br />
                  <input
                    className="wid-80"
                    type="text"
                    name="no_perusahaan"
                    required
                  />
                </div>
              </div>

              <div className="contact-grid">
                <div className="form-contact">
                  <label>Saya lebih senang dihubungi</label>
                  <br />
                  <input type="checkbox" name="via_no_kantor" />{" "}
                  <span>Melalui nomor telepon kantor</span>
                  <br />
                  <input type="checkbox" name="via_no_pribadi" />{" "}
                  <span>Melalui nomor telepon pribadi</span>
                  <br />
                  <input type="checkbox" name="via_email" /> <span>Email</span>
                </div>
                <div className="form-contact">
                  <label>Waktu terbaik saya dihubungi</label>
                  <br />
                  <input type="checkbox" name="pagi" />{" "}
                  <span>Pagi (08.00-12.00)</span>
                  <br />
                  <input type="checkbox" name="siang" />{" "}
                  <span>Makan Siang (12.00-13.30)</span>
                  <br />
                  <input type="checkbox" name="sore" />{" "}
                  <span>Sore (13.00-17.00)</span>
                </div>
              </div>

              <div className="form-contact">
                <label>Email</label>
                <br />
                <input className="wid-40" type="email" name="email" required />
              </div>

              <div className="form-contact">
                <label>Butuh Informasi Mengenai</label>
                <br />
                <input type="checkbox" name="babp" />{" "}
                <span>Be Agile, Be Productive</span>
                <br />
                <input type="checkbox" name="ct" />{" "}
                <span>Creative Thinking</span>
                <br />
                <input type="checkbox" name="ssfh" />{" "}
                <span>Smart Selling From Home</span>
              </div>

              <div className="form-contact">
                <label>Pertanyaan</label>
                <br />
                <textarea className="wid-40" rows="8"></textarea>
              </div>

              <div className="form-control">
                <button type="submit" name="kirim" className="btn-regis">
                  KIRIM
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
