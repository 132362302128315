import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import moment from "moment"

import ScheduleItem from "./modules/ScheduleItem"

const ScheduleByProgram = ({ program }) => {
	const [schedules, setSchedules] = useState([])
	const [options, setOptions] = useState({
		month: "",
		speakerId: "",
		programId: "",
	})
	const [pagination, setPagination] = useState({
		total: null,
		per_page: null,
		current_page: 1,
		total_pages: null,
		start_index: null,
	})
	const { total, per_page, current_page, total_pages, start_index } = pagination

	useEffect(() => {
		if (program) {
			loadSchedules(1)
		}
		//eslint-disable-next-line
	}, [program])

	async function loadSchedules(pageNumber = 1) {
		try {
			const schedules = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/schedules/schedulesbyprogram/${program._id}/${pageNumber}`
			)

			const config = await axios.get(
				`${process.env.REACT_APP_APIURL}api/v1/configuration/get`
			)

			let newSchedules = []
			let newSchedulesClose = []
			const todayTimestamp = moment().format("x")

			schedules.data.data.forEach((schedule) => {
				const eventdateTimestamp = moment(schedule.date).format("x")
				const dayDiff =
					(eventdateTimestamp - todayTimestamp) / (1000 * 60 * 60 * 24)

				if (
					schedule.stocks === 0 ||
					dayDiff <= config.data.data.normalpriceDaybefore
				) {
					schedule.isOpen = false
					newSchedulesClose.push(schedule)
				} else {
					schedule.isOpen = true
					newSchedules.push(schedule)
				}
			})

			newSchedules.push(...newSchedulesClose)

			if (newSchedules.length > 0) {
				setSchedules(newSchedules)
			}

			setPagination({
				...pagination,
				total: schedules.data.total,
				per_page: schedules.data.per_page,
				current_page: schedules.data.page,
				total_pages: schedules.data.total_pages,
				start_index: schedules.data.startIndex,
			})
		} catch (err) {
			console.log(err.response.data, "danger")
		}
	}

	const pageNumbers = []
	let renderPageNumbers

	if (total !== null) {
		for (let i = 1; i <= Math.ceil(total / per_page); i++) {
			pageNumbers.push(i)
		}

		renderPageNumbers = pageNumbers.map((number) => {
			let classLink = current_page === number ? "active" : ""
			let classList = current_page === number ? "active" : ""
			return (
				<li className={classList} key={number}>
					<Link
						to="#"
						className={classLink}
						onClick={() => loadSchedules(number)}
					>
						{number}
					</Link>
				</li>
			)
		})
	}

	//get scheduleLastIndex
	let scheduleLastIndex
	if (current_page === total_pages) {
		//this is the last page
		scheduleLastIndex = total
	} else {
		scheduleLastIndex = start_index + per_page
	}

	return (
		<section className="py-2">
			<div className="container">
				<div className="row">
					<h1 className="sectionTitle">JADWAL</h1>
					{/* <div className="chooseJadwal">
            <select className="bulan" onChange={changeMonth}>
              <option value="">Bulan</option>
              <option value="1">Januari</option>
              <option value="2">Februari</option>
              <option value="3">Maret</option>
              <option value="4">April</option>
              <option value="5">Mei</option>
              <option value="6">Juni</option>
              <option value="7">Juli</option>
              <option value="8">Agustus</option>
              <option value="9">September</option>
              <option value="10">Oktober</option>
              <option value="11">November</option>
              <option value="12">Desember</option>
            </select>
            <select className="instruktur" onChange={changeSpeaker}>
              <option value="">Instruktur</option>
              {instructors.length > 0 && instructors.map(person => <option value={person._id}>{person.name}</option>)} 
            </select>
          </div> */}
					{schedules.length > 0 &&
						schedules.map((schedule) => (
							<ScheduleItem
								background="bg-grey"
								key={schedule._id}
								schedule={schedule}
							/>
						))}
					<div className="pagination-wrapper" style={{ borderTop: "none" }}>
						<ul className="pagination">
							<li>
								<Link to="#" onClick={() => loadSchedules(1)}>
									<i className="fa fa-angle-double-left"></i>
								</Link>
							</li>
							{renderPageNumbers}
							<li>
								<Link to="#" onClick={() => loadSchedules(total_pages)}>
									<i className="fa fa-angle-double-right"></i>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ScheduleByProgram
