import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { slide as Menu } from 'react-burger-menu'

import AuthContext from "../../context/auth/authContext";

const Header = () => {
  const [partners, setPartners] = useState([]);
  const authContext = useContext(AuthContext);
  const { isAuthenticated, currentUser, loadUser } = authContext;

  useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadPartners();
    //eslint-disable-next-line
  }, []);

  async function loadPartners() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/businesspartners/all/frontend`
      );
      setPartners(res.data.data);
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  return (
    <>
    <section id="main-nav">
      <div className="container">
        <div id="center-nav">
          <Link to="/" id="logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo.jpg"}
              alt="NLP Indonesia" 
            />
          </Link>
          <ul>
            <li>
              <Link to="/">
                BERANDA
              </Link>
            </li>
            <li>
              <Link to="">
                TENTANG <i className="fa fa-chevron-down"></i>
              </Link>
              <ul className="topMegaMenu">
                <li className="level-2">
                  <a href="/about">
                    TENTANG AKADEMI NLP INDONESIA &nbsp;&nbsp;&nbsp;
                  </a>
                </li>
                <li>
                  <a href={`/about#AboutHing`}>TENTANG HINGDRANATA NIKOLAY</a>
                </li>
                <li className="level-2"> 
                  <a href="/about#AboutIns">
                    TENTANG INSTRUKTUR BERLISENSI &nbsp;&nbsp;&nbsp;
                    <i className="fa fa-chevron-right"></i>
                  </a>
                  <ul className="subMegaMenu">
                    {partners.length > 0 &&
                      partners.map((partner) => (
                        <li key={partner._id}>
                          <a
                            href={`/instruktur/${partner.slug}`}
                            style={{ textTransform: "uppercase" }}
                          >
                            {partner.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/jadwal">JADWAL</Link>
            </li>
            <li>
              <Link to="/program">PROGRAM</Link>
            </li>
            <li>
              <Link to="/layanan">LAYANAN <i className="fa fa-chevron-down"></i></Link>
              <ul className="topMegaMenu">
                <li className="level-2">
                  <Link to="/layanan/In-House-Training">
                    IN-HOUSE TRAINING &nbsp;&nbsp;&nbsp;
                  </Link>
                </li>
                <li>
                  <Link to="/layanan/Virtual-Workshop">VIRTUAL WORKSHOP</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/kontak">HUBUNGI KAMI</Link>
            </li>
          </ul>
          <div id="accountIcons">
            {isAuthenticated && currentUser ? (
              <>
                <Link
                  to="/account/dashboard"
                  style={{ textTransform: "uppercase" }}
                >
                  <i className="fa fa-cog"></i> {currentUser.name}
                </Link>
                {"   "}|{" "}
                <Link to="/logout">
                  <i className="fa fa-sign-out"></i> LOGOUT
                </Link>
              </>
            ) : (
              <>
                <Link to="/register">
                  <i className="fa fa-user"></i> DAFTAR MEMBER
                </Link>{" "}
                |
                <Link to="/login">
                  <i className="fa fa-sign-out"></i> LOGIN
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
    <Menu width={ 350 }>
    <div id="accountIconsMobile">
          {isAuthenticated && currentUser ? (
            <>
              <a
                href="/account/dashboard"
                style={{ textTransform: "uppercase" }}
              >
                <i className="fa fa-cog"></i> {currentUser.name}
              </a>
              {"   "}
              <a href="/logout">
                <i className="fa fa-sign-out"></i> LOGOUT
              </a>
            </>
          ) : (
            <>
              <a href="/register">
                <i className="fa fa-user"></i> DAFTAR MEMBER
              </a>{"   "}
              <a href="/login">
                <i className="fa fa-sign-out"></i> LOGIN
              </a>
            </>
          )}
        </div>
      <ul>
          <li class="level1">
            <a href="/">
              BERANDA
            </a>
          </li>
          <li class="level1">
            <a href="/about">
              TENTANG
            </a>
            <i className="fa fa-chevron-down" style={{float:'right'}}></i>
            <ul className="topMegaMenu">
              <li className="level-2">
                <a href="/about">
                  TENTANG AKADEMI NLP INDONESIA &nbsp;&nbsp;&nbsp;
                </a>
              </li>
              <li className="level-2">
                <a href={`/about#AboutHing`}>TENTANG HINGDRANATA NIKOLAY</a>
              </li>
              <li className="level-2"> 
                <a href="/about#AboutIns">
                  TENTANG INSTRUKTUR BERLISENSI
                </a>
                {/* <ul className="subMegaMenu ulLevelThree">
                  {partners.length > 0 &&
                    partners.map((partner) => (
                      <li key={partner._id}>
                        <a
                          href={`/instruktur/${partner.slug}`}
                          style={{ textTransform: "uppercase" }}
                        >
                          {partner.name}
                        </a>
                      </li>
                    ))}
                </ul> */}
              </li>
            </ul>
          </li>
          <li class="level1">
            <a href="/jadwal">JADWAL</a>
          </li>
          <li class="level1">
            <a href="/program">PROGRAM</a>
          </li>
          <li class="level1">
            <a href="/layanan">LAYANAN</a>
            <i className="fa fa-chevron-down" style={{float:'right'}}></i>
            <ul className="topMegaMenu">
              <li className="level-2">
                <a href="/layanan/In-House-Training">
                  IN-HOUSE TRAINING &nbsp;&nbsp;&nbsp;
                </a>
              </li>
              <li className="level-2">
                <a href="/layanan/Virtual-Workshop">VIRTUAL WORKSHOP</a>
              </li>
            </ul>
          </li>
          <li class="level1">
            <a href="/kontak">HUBUNGI KAMI</a>
          </li>
        </ul>
    </Menu>
    </>
  );
};

export default Header;
