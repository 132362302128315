import React, { useEffect, useState } from "react";
import axios from "axios";
import { EditorState, convertFromRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Markup } from "interweave";

const ServiceDetail = ({ match }) => {
  const [service, setService] = useState([]);
  const [intro, setIntro] = useState(EditorState.createEmpty());
  const [content, setContent] = useState(EditorState.createEmpty());

  useEffect(() => {
    loadService(match.params.alias);
    //eslint-disable-next-line
  }, [match.params.alias]);

  async function loadService(alias) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/services/alias/${alias}`
      );

      setService(res.data.data);

      setContent(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(res.data.data.content))
        )
      );

      setIntro(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(res.data.data.introduction))
        )
      );
    } catch (err) {
      console.log(err.response.data, "danger");
    }
  }

  const introText = stateToHTML(intro.getCurrentContent());
  const contentText = stateToHTML(content.getCurrentContent());

  return (
    <section className="py-2" id="layananDetail">
      <div className="container">
        <div className="row">
          <h1 className="sectionTitle">{service.name}</h1>
          <div className="layanan layanan-intro">
            <img
              className="img-service"
              src={`${process.env.REACT_APP_APIURL}uploads/services/${service.thumbnail}`}
              alt=""
            />
            <div className="layanan-info">
              <Markup content={introText} />
            </div>
          </div>

          <div className="layanan-detail">
            <Markup content={contentText} />
          </div>
          {service.video && (
            <div className="videoIntro">
              <video controls>
                <source
                  src={`${process.env.REACT_APP_APIURL}uploads/services/${service.video}`}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceDetail;
