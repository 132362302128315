import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";

import AkunProgram from '../modules/AkunProgram';

const Dashboard = () => {
  const [userData, setUserData] = useState({
    role: "",
    referralCode: "",
    businessPartnerEndDate: "",
    countOrder: "",
    isInstructor: false,
  });
  const { role, referralCode, businessPartnerEndDate, countOrder, isInstructor } = userData;

  useEffect(() => {
    loadUser();
  }, []);

  async function loadUser() {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/users/getuserdetail`
      );

      const countOrder = await axios.get(
        `${process.env.REACT_APP_APIURL}api/v1/orders/countorder`
      );

      setUserData({
        role: res.data.data.role,
        referralCode: res.data.data.referralCode,
        businessPartnerEndDate: res.data.data.businessPartnerEndDate,
        countOrder: countOrder.data.data,
        isInstructor: res.data.data.isInstructor,
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      <h1>Beranda</h1>
      <hr />
      <p>Selamat Datang Member Akademi NLP Indonesia.</p>

      {(() => {
        if (role === "partner") {
          return (
            <>
              <p>
                Selamat, Anda telah menjadi Business Partner di Akademi NLP
                Indonesia. keanggotaan Anda berlaku s/d{" "}
                <strong>
                  {moment(businessPartnerEndDate).format("DD MMM YYYY")}
                </strong>
              </p>
              <h3>
                Ini adalah Referral Code Anda:{" "}
                <span
                  style={{
                    color: "white",
                    background: "green",
                    padding: "0.1rem 2rem",
                  }}
                >
                  {referralCode}
                </span>
              </h3>
              <p>
                Silahkan bagikan Referral Code ini kepada teman maupun keluarga
                Anda untuk mendapatkan benefit redeem dari program Akademi NLP
                Indonesia.
              </p>
            </>
          );
        } else {
          if (isInstructor === true || countOrder > 0) {
            return (
              <>
                <p>
                  Selamat! Anda telah memenuhi persyaratan upgrade menjadi
                  Business Partner.
                </p>
                <br />
                <p>
                  <Link
                    to="/account/upgrade-business-partner"
                    className="btn-regis"
                  >
                    UPGRADE BUSINESS PARTNER
                  </Link>
                </p>
              </>
            );
          }
        }
      })()}
      <AkunProgram />
    </Fragment>
    
  );
};

export default Dashboard;
