import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/modules/Header";
import Footer from "./components/modules/Footer";
import Home from "./components/Home";
import Category from "./components/browseCategory/BrowseCategory";
import Product from "./components/product/Product";
import Search from "./components/search/Search";
import Login from "./components/account/Login";
import ResetPassword from "./components/account/ResetPassword";
import ProcessResetPassword from "./components/account/ProcessResetPassword";
import Logout from "./components/account/Logout";
import Register from "./components/account/Register";
import VerifyRegister from "./components/account/VerifyRegister";
import RegisterSosmed from "./components/account/RegisterSosmed";
import NotFound from "./components/modules/NotFound";
import Alerts from "./components/modules/Alerts";
import Account from "./components/account/Account";
import AlertState from "./context/alert/AlertState";
import AuthState from "./context/auth/authState";
import CartState from "./context/cart/cartState";
import WishlistState from "./context/wishlist/wishlistState";
import CustomerRoute from "./components/routing/CustomerRoute";
import CheckoutMarketplace from "./components/checkoutMarketplace/CheckoutMarketplace";
import Cart from "./components/cart/Cart";
import ScrollToTop from "./utils/ScrollToTop";
import Toc from "./components/Toc";
import Faq from "./components/Faq";
import About from "./components/About";
import Contact from "./components/Contact";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { Helmet } from "react-helmet";
import Thankyou from "./components/Thankyou";
import Pembayaran from "./components/checkoutMarketplace/pembayaran/Pembayaran";
import Schedule from "./components/Schedule";
import Program from "./components/Program";
import Services from "./components/Services";
import Instruktur from "./components/Instruktur";
import ServiceDetail from "./components/ServiceDetail";
import ProgramDetail from "./components/ProgramDetail";
import ScheduleDetail from "./components/ScheduleDetail";
import CourseCheckout from "./components/CourseCheckout";
import CourseCheckoutGuest from "./components/CourseCheckoutGuest";
import CoursePayment from "./components/CoursePayment";
import CoursePaymentGuest from "./components/CoursePaymentGuest";
import BeAgile from './components/BeAgile';
import CreativeThinking from './components/CreativeThinking';
import SmartSelling from './components/SmartSelling';

const App = () => {
  return (
    <>
      <Helmet>
        <title>Akademi NLP Indonesia</title>
        <meta name="description" content={``} />
      </Helmet>
      <AuthState>
        <AlertState>
          <CartState>
            <WishlistState>
              <Router>
                <ScrollToTop>
                  <Header />
                  <Alerts />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route
                      exact
                      path="/category/:category"
                      component={Category}
                    />
                    <Route
                      exact
                      path="/category/:category/:subcategory1"
                      component={Category}
                    />
                    <Route
                      exact
                      path="/category/:category/:subcategory1/:subcategory2"
                      component={Category}
                    />
                    <Route exact path="/search/" component={Search} />
                    <Route path="/product/:alias" component={Product} />
                    <Route exact path="/cart" component={Cart} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route exact path="/faq" component={Faq} />
                    <Route exact path="/about" component={About} />
                    <Route exact path="/jadwal" component={Schedule} />
                    <Route exact path="/program" component={Program} />
                    <Route exact path="/layanan" component={Services} />
                    <Route path="/instruktur/:alias" component={Instruktur} />
                    <Route path="/layanan/:alias" component={ServiceDetail} />
                    <Route path="/program/:alias" component={ProgramDetail} />
                    <Route path="/jadwal/:id" component={ScheduleDetail} />
                    <Route
                      path="/checkout-program-guest/:id"
                      component={CourseCheckoutGuest}
                    />
                    <Route
                      path="/checkout-payment-guest"
                      component={CoursePaymentGuest}
                    />
                    <CustomerRoute
                      path="/checkout-program/:id"
                      component={CourseCheckout}
                    />
                    <CustomerRoute
                      path="/checkout-payment"
                      component={CoursePayment}
                    />
                    <Route exact path="/kontak" component={Contact} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route
                      exact
                      path="/verify/:token"
                      component={VerifyRegister}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      component={ResetPassword}
                    />
                    <Route
                      exact
                      path="/proses-reset-password/:token"
                      component={ProcessResetPassword}
                    />
                    <Route
                      exact
                      path="/register-sosmed/:name/:email"
                      component={RegisterSosmed}
                    />
                    <Route exact path="/logout" component={Logout} />
                    <Route exact path="/toc" component={Toc} />
                    <Route exact path="/thankyou" component={Thankyou} />
                    <CustomerRoute
                      path="/account/:accountPage"
                      component={Account}
                    />
                    <CustomerRoute
                      path="/checkout/:paymentId"
                      component={Pembayaran}
                    />
                    <CustomerRoute
                      path="/checkout"
                      component={CheckoutMarketplace}
                    />
                    <Route exact path="/be-agile" component={BeAgile} />
                    <Route exact path="/creative-thinking" component={CreativeThinking} />
                    <Route exact path="/smart-selling" component={SmartSelling} />
                    <Route path="/" component={NotFound} />
                  </Switch>
                  <Footer />
                </ScrollToTop>
              </Router>
            </WishlistState>
          </CartState>
        </AlertState>
      </AuthState>
    </>
  );
};

export default App;
